import { useEffect } from "react";
import DropdownList from "../../shared/DropdownList";

export default function DropdownContent({ page, htmlAnswer, htmlQuestion }) {
  useEffect(() => {
    setTimeout(function () {
      const hiddenContent = document.getElementById("page-content");
      if (hiddenContent) hiddenContent.classList.add("animated");
    }, 100);
  }, [ page ]);
  useEffect(() => {
    const hiddenContent = document.getElementById("page-content");
    if (hiddenContent) {
      setTimeout(() => {
        hiddenContent.classList.add("animated");
      }, 50);
    }
  }, [ page ]);
  return (
    <article>
      {page && (
        <div
          id="page-content"
          className="hideme mb-4 p-8 pt-12 z-50 min-h-full transe-ease-slow"
        >
          <h1 className="text-center min-[768px]:text-left font-frutiger-light uppercase font-bold text-[#24292e] mb-4 min-[768px]:mb-6">
            {page.title}
          </h1>
          <div className="post-content mt-4 xl:mt-8 float-images-left">
            {page?.pageContent && (
              <DropdownList
                htmlAnswer={htmlAnswer}
                htmlQuestion={htmlQuestion}
                items={page.pageContent}
              />
            )}
          </div>
        </div>
      )}
    </article>
  );
}
