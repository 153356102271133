import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";

import TestimonialsFromOurStaffContent from "../components/shared/layouts/TestimonialsFromOurStaffContent";

import { gql, useQuery } from "@apollo/client";
import usePage from "../contexts/PageContext";
import { ADD_LOCATIONS_WITH_CONTENT, ADD_PAGES } from "../lib/graphql";

export default function TestimonialsFromOurStaff(props) {
  const { siteData, setSiteData } = usePage();

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES;
  if (!siteData?.locations || !siteData.locations.length > 0)
    curQueries = curQueries + ADD_LOCATIONS_WITH_CONTENT;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip:
      siteData?.pages[curLocation] &&
      siteData?.locations &&
      siteData.locations.length > 0,
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      let locations = pageData?.locations?.edges || [];

      if (featuredImage?.mediaItemUrl)
        newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      newSiteData.locations = locations;
      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: { ...newPage },
      };

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curChildren: [],
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[slugId];

  useEffect(() => {
    if (curPage) {
      if (curPage && !curPage?.seo) {
        console.log("missing SEO!!! curPage", curPage);
      }

      setSiteData({
        ...siteData,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;
  return (
    <div className="background-blue-gradient w-full h-full">
      <div className="absolute top-0 background-blue-gradient w-full h-full">
        <div className="relative top-0 background-blue-gradient w-full h-full">
          <div
            id="inner-wrapper"
            data-speed="100"
            className="absolute w-full h-full top-0"
            style={{
              background:
                "url('/images/grid-blue.png') center center repeat fixed",
            }}
          />
        </div>
      </div>
      <div className="page-container z-10 py-4 px-0 relative bg-transparent overflow-hidden">
        {curPage && curPage?.seo && <MetaHead seoObject={curPage} />}
        <main
          id="main-container"
          className=" m-auto -mt-4 min-[1024px]:mt-4 min-[1366px]:mt-8 mb-[60px]"
        >
          {siteData?.locations && (
            <TestimonialsFromOurStaffContent locations={siteData.locations} />
          )}
        </main>
      </div>
    </div>
  );
}
