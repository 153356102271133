import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { TEInput, TESelect } from "tw-elements-react";

import {
  corporateOnlineApplicationID,
  defaultValues,
  officeAppliedToOptions,
  soonestAvailableOptions,
  visaTypeOptions,
  wpcf7Url,
} from "../lib/constants";

import {
  CustomCheckbox,
  CustomControlledDatePicker,
  CustomControlledInput,
  CustomInput,
} from "../lib/form-helper";

import { useErrorScroll } from "hooks/uerErrorScroll";

export default function OnlineApplicationContent({ page }) {
  const location = useLocation();

  //these are also in the functions.php file, as default for corp email settings in wp admin
  // {"Tokyo Corporate":"corp-recruiting-e@corp.aeonet.co.jp","Nagoya Corporate":"corp-recruiting-c@corp.aeonet.co.jp","Osaka Corporate":"corp-recruiting-ek@corp.aeonet.co.jp","Okayama Corporate":"awj-hojin@corp.aeonet.co.jp"}

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [ location.search ]
  );
  const interviewType = queryParams.get("type");

  const formId = corporateOnlineApplicationID;

  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors, isValid, isValidating },
  } = useForm();

  const [ newFormData, setFormData ] = useState({ ...defaultValues });
  const [ userName, setUserName ] = useState(null);
  const [ isSubmitting, setisSubmitting ] = useState(false);

  const pageRef = useRef();
  useErrorScroll(errors);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  useEffect(() => {
    const newParams = {
      addLocation: queryParams.get("add-location") || null,
      addApplicationPeriod: queryParams.get("add-application-period") || null,
      addDate: queryParams.get("add-date") || null,
    };

    const newFormDataItems = {
      ...newFormData,
      type: interviewType,
      ...newParams,
    };

    setFormData(newFormDataItems);

    if (page?.successObject) {
      const placeholder = escapeRegExp("[corporate-office]");
      page.successObject.corporateSuccess =
        page.successObject.corporateSuccess.replace(
          new RegExp(placeholder, "g"),
          newParams.addLocation
        );
    }
  }, [ page ]);

  const onInvalid = (e) => {
    if (errors && Object.keys(errors).length > 0) {
      const firstErrorElement = document.querySelector(".text-\\[\\#e80000\\]");

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const onSubmit = (data) => {
    setisSubmitting(true);
    const fileResume = document.getElementById("file-resume").files[0];
    const fileCover = document.getElementById("file-cover").files[0];
    const sameAddress = document.getElementById("same-address").value;

    const soonestAvailable = data["soonest-available"].text;

    const formData = new FormData();

    for (const key in data) {
      if (key !== "file-resume" && key !== "file-cover") {
        formData.append(key, data[key]?.value || data[key]);
        if (key === "first-name") {
          setUserName(data[key]?.value || data[key]);
        }
      }
    }

    formData.append("_wpcf7_unit_tag", formId);
    formData.append("file-cover", fileCover);
    formData.append("file-resume", fileResume);
    formData.append("type", interviewType);
    formData.append("same-address", sameAddress);
    formData.append("applied-before", newFormData.appliedBefore);
    formData.append("has-degree", newFormData.hasDegree);
    formData.append("has-passport", newFormData.hasPassport);
    formData.append("has-visa", newFormData.hasVisa);
    formData.append("soonest-available", soonestAvailable);
    formData.append("office", newFormData.addLocation);
    formData.append("_wpcf7", formId.toString());
    formData.append(
      "_wpcf7cf_hidden_group_fields",
      JSON.stringify([ "received-date" ])
    );
    formData.append(
      "_wpcf7cf_options",
      JSON.stringify({
        form_id: formId,
        settings: {
          animation: "yes",
          animation_intime: 200,
          animation_outtime: 200,
          conditions_ui: "normal",
          notice_dismissed: false,
        },
      })
    );
    // formData.append(
    //   "_wpcf7cf_options",
    //   JSON.stringify({
    //     form_id: formId,
    //     // conditions: [
    //     //   {
    //     //     then_field: "status-selected",
    //     //     and_rules: [
    //     //       { if_field: "office", operator: "not equals", if_value: "Texas" },
    //     //     ],
    //     //   },
    //     //   {
    //     //     then_field: "-1",
    //     //     and_rules: [ { if_field: "-1", operator: "equals", if_value: "" } ],
    //     //   },
    //     // ],

    const fetchData = async () => {
      const url = wpcf7Url(formId);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      try {
        const results = await axios.post(url, formData, config);
        if (results.statusText === "OK") {
          if (
            results?.data?.invalid_fields &&
            results.data.invalid_fields.length > 0
          ) {
            setFormData({
              ...newFormData,
              isSuccess: false,
              isInvalid: true,
              errors: [ ...results.data.invalid_fields ],
              errorMessage: results?.data?.message || null,
            });
          } else {
            setFormData({ ...newFormData, isSuccess: true, isInvalid: false });
          }

          window.scrollTo({
            top: 18,
            behavior: "smooth",
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error.response);
        setFormData({
          ...newFormData,
          isError: true,
          errorMessage:
            "An unknown error has occurred, please contact us for assistance.",
        });
      } finally {
        setisSubmitting(false);
      }
    };

    fetchData();
  };

  return (
    <article
      ref={pageRef}
      className="p-8 pt-12 bg-[#fcfcfc] z-50 min-h-full text-base"
    >
      {page && (
        <div className="trans-ease-slow">
          <h1 className="font-frutiger-light text-[30px] font-bold uppercase text-[#24292e] mb-4 xl:mb-8">
            {page.title}
          </h1>
          <ul className="large-blue list-unstyled corporate">
            {newFormData.addLocation && (
              <li>
                <span className="blue-strong">Office to Apply: </span>
                <span>{newFormData.addLocation}</span>
              </li>
            )}
            {newFormData.addApplicationPeriod && (
              <li>
                <span className="blue-strong">Application Period: </span>
                <span>{newFormData.addApplicationPeriod}</span>
              </li>
            )}
            {newFormData.addDate && (
              <li>
                <span className="blue-strong">Next Available Interview: </span>
                <span>{newFormData.addDate}</span>
              </li>
            )}
          </ul>
          <div
            className={`${
              newFormData?.isSuccess ? "h-0" : "h-auto"
            } trans-ease-slow`}
          >
            {newFormData?.isSuccess && (
              <>
                <h4 className="blue-strong text-center mt-2 md:mt-6">
                  Thank You, {userName}!
                </h4>
                <div
                  className="post-content mt-4 mb-8 xl:mt-8 trans-ease-slow"
                  dangerouslySetInnerHTML={{
                    __html: page.successObject.corporateSuccess,
                  }}
                />
              </>
            )}
          </div>
          {newFormData?.isError && (
            <div
              className="post-content mt-4 mb-8 xl:mt-8 trans-ease-slow"
              dangerouslySetInnerHTML={{ __html: page.newPageError }}
            />
          )}
          {!newFormData?.isError && !newFormData?.isSuccess && (
            <>
              <div
                className="post-content mt-4 mb-8 xl:mt-8"
                dangerouslySetInnerHTML={{
                  __html: page.toApplyObject.toApplyToAeonCorporate,
                }}
              />
              {newFormData?.isInvalid && (
                <div className="mb-2">
                  <span className="text-[#e80000] mb-2 block">
                    {newFormData?.errorMessage && newFormData.errorMessage}
                  </span>
                  {newFormData?.errors?.length && (
                    <div className="mb-4">
                      <span>
                        The following fields were found to be invalid:
                        {newFormData.errors.map((error, index) => {
                          return (
                            <span key={error.field}>
                              {index < newFormData.errors.length - 1 && ","}
                              <span className="ml-1">{error.field}</span>
                            </span>
                          );
                        })}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <form
                noValidate
                className="online-form invalidated"
                onSubmit={handleSubmit(onSubmit, onInvalid)}
              >
                <section className="mb-6">
                  <h4 className="blue-strong mb-4">Contact Information</h4>
                  <div className="px-1 grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-5 mb-2">
                    <CustomInput
                      register={register}
                      error={errors["first-name"]}
                      label="First Name"
                      keyField="first-name"
                      rules={{ required: true, maxLength: 56 }}
                    />
                    <TEInput
                      type="text"
                      label="Middle Name"
                      {...register("middle-name", { maxLength: 56 })}
                    ></TEInput>
                    <CustomInput
                      register={register}
                      error={errors["last-name"]}
                      label="Last Name"
                      keyField="last-name"
                      rules={{ required: true, maxLength: 56 }}
                    />
                    <CustomInput
                      register={register}
                      error={errors["phone"]}
                      label="Phone"
                      keyField="phone"
                      rules={{ required: true, maxLength: 56 }}
                      type="tel"
                    />
                    <TEInput
                      type="tel"
                      label="Alternate Phone"
                      {...register("alt-phone")}
                    ></TEInput>
                    <CustomInput
                      register={register}
                      error={errors["email"]}
                      label="Email address"
                      keyField="email"
                      rules={{ required: true, maxLength: 56 }}
                    />
                  </div>
                </section>
                <section className="mb-6">
                  <h4 className="blue-strong inline-block mb-4">
                    Current Address
                  </h4>
                  <span className="ml-2 inline-block text-sm mb-2">
                    (Place where you are living now)
                  </span>
                  <div className="px-1 grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-5 mb-2">
                    <CustomInput
                      register={register}
                      error={errors["address"]}
                      label="Address 1"
                      keyField="address"
                      rules={{ required: true, maxLength: 56 }}
                      type="text"
                    />
                    <TEInput
                      type="text"
                      label="Address 2"
                      className=""
                      {...register("address-2", { maxLength: 56 })}
                    ></TEInput>
                  </div>
                  <div className="px-1 grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-5 mt-5 mb-2">
                    <CustomInput
                      register={register}
                      error={errors["city"]}
                      label="City"
                      keyField="city"
                      rules={{ required: true, maxLength: 128 }}
                      type="text"
                    />
                    <CustomInput
                      register={register}
                      error={errors["state"]}
                      label="State/Province"
                      keyField="state"
                      rules={{ required: true, maxLength: 56 }}
                      type="text"
                    />
                    <CustomInput
                      register={register}
                      error={errors["postal-code"]}
                      label="Zip/Post Code"
                      keyField="postal-code"
                      rules={{ required: true, maxLength: 32 }}
                      type="text"
                    />
                  </div>
                  <div className="px-1 grid grid-cols-1 lg:grid-cols-2 gap-x-2 mt-5 gap-y-4">
                    <CustomInput
                      register={register}
                      error={errors["country"]}
                      label="Country"
                      keyField="country"
                      rules={{ required: true, maxLength: 48 }}
                      type="text"
                    />
                  </div>
                </section>
                <section className="mb-2">
                  <h4 className="blue-strong inline-block mb-2">
                    Permanent Address
                  </h4>
                  <span className="ml-2 inline-block text-sm mb-2">
                    (If different from your current address)
                  </span>
                  <div className="min-h-[1.5rem]">
                    <CustomCheckbox
                      type="checkbox"
                      checked={newFormData.sameAddress === "Yes"}
                      value="Yes"
                      id="same-address"
                      keyField="same-address"
                      label="Same as current address"
                      onChange={(e) => {
                        setFormData({
                          ...newFormData,
                          sameAddress: e.target.checked ? "Yes" : "No",
                        });
                        if (!e.target.checked) {
                          register("perm-address", {
                            required: true,
                            maxLength: 256,
                          });
                          register("perm-address-2", { maxLength: 256 });
                          register("perm-city", {
                            required: true,
                            maxLength: 256,
                          });
                          register("perm-state", {
                            required: true,
                            maxLength: 256,
                          });
                          register("perm-postal-code", {
                            required: true,
                            maxLength: 256,
                          });
                          register("perm-country", {
                            required: true,
                            maxLength: 256,
                          });
                        } else {
                          unregister("perm-address");
                          unregister("perm-address-2");
                          unregister("perm-city");
                          unregister("perm-state");
                          unregister("perm-postal-code");
                          unregister("perm-country");
                        }
                      }}
                    />
                    <div
                      className={`mt-2 block overflow-hidden ${
                        newFormData.sameAddress !== "Yes"
                          ? "h-auto visible opacity-100"
                          : "opacity-0 invisible h-0"
                      } flex transition-all trans-ease-all w-full`}
                    >
                      {newFormData.sameAddress !== "Yes" && (
                        <div className="w-full mb-4">
                          <div className="px-1 grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-5 mb-2 pt-2">
                            <CustomControlledInput
                              error={errors["perm-address"]}
                              keyField="perm-address"
                              type="text"
                              rules={{ required: true }}
                              label="Address 1"
                              control={control}
                            />
                            <CustomControlledInput
                              keyField="perm-address-2"
                              type="text"
                              label="Address 2"
                              control={control}
                            />
                          </div>
                          <div className="px-1 grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-5 mt-5 mb-2">
                            <CustomControlledInput
                              error={errors["perm-city"]}
                              keyField="perm-city"
                              type="text"
                              rules={{ required: true }}
                              label="City"
                              control={control}
                            />
                            <CustomControlledInput
                              error={errors["perm-state"]}
                              keyField="perm-state"
                              type="text"
                              rules={{ required: true }}
                              label="State/Province"
                              control={control}
                            />
                            <CustomControlledInput
                              error={errors["perm-postal-code"]}
                              keyField="perm-postal-code"
                              type="text"
                              rules={{ required: true }}
                              label="Zip/Post Code"
                              control={control}
                            />
                          </div>
                          <div className="px-1 grid grid-cols-1 lg:grid-cols-2 gap-x-3 mt-5 gap-y-5">
                            <CustomControlledInput
                              error={errors["perm-country"]}
                              keyField="perm-country"
                              type="text"
                              rules={{ required: true }}
                              label="Country"
                              control={control}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                <section>
                  <label
                    className="required inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor="appliedBefore"
                  >
                    Have you applied to or worked for AEON before?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.appliedBefore === "Yes"}
                      value="Yes"
                      id="has-applied"
                      keyField="applied-before"
                      onChange={() => {
                        setFormData({ ...newFormData, appliedBefore: "Yes" });
                        register("office-applied", {
                          required: true,
                          maxLength: 256,
                        });
                        register("year-applied", {
                          required: true,
                          maxLength: 256,
                        });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.appliedBefore === "No"}
                      value="No"
                      id="has-not-applied"
                      keyField="applied-before"
                      onChange={() => {
                        setFormData({ ...newFormData, appliedBefore: "No" });
                        unregister("office-applied");
                        unregister("year-applied");
                      }}
                    />
                  </div>
                  <div
                    className={`mt-4 ml-4 overflow-hidden ${
                      newFormData.appliedBefore === "Yes"
                        ? "h-auto visible opacity-100"
                        : "opacity-0 invisible h-0"
                    } flex transition-all trans-ease-all w-full top-5 right-3`}
                  >
                    {newFormData.appliedBefore === "Yes" && (
                      <div className="flex flex-col">
                        <label
                          className="blue pl-[0.15rem] hover:cursor-pointer"
                          htmlFor="exampleCheck3"
                        >
                          The office and year you applied:
                        </label>
                        <div className="px-1 grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-6">
                          <div className="input-wrapper">
                            <Controller
                              rules={{ required: true }}
                              control={control}
                              name="office-applied"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <TESelect
                                  clearBtn
                                  rules={{ required: true }}
                                  preventFirstSelection
                                  data={officeAppliedToOptions}
                                  label={`Office Applied To`}
                                  className="styled-clear max-w-[440px] w-full required"
                                  id="office-applied"
                                  onBlur={onBlur}
                                  selected={value}
                                  onValueChange={onChange}
                                  theme={{
                                    selectInput:
                                      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear peer-focus:text-primary motion-reduce:transition-none disabled:bg-neutral-100 dark:disabled:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary cursor-pointer disabled:cursor-default px-3 py-[0.32rem] leading-[1.6] text-neutral-800 dark:text-neutral-200 required",
                                  }}
                                ></TESelect>
                              )}
                            />

                            <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">
                              {errors["office-applied"] &&
                                "This field is required."}
                            </span>
                          </div>
                          <CustomControlledDatePicker
                            rules={{ required: true }}
                            error={errors["year-applied"]}
                            views={[ "year" ]}
                            label="Year"
                            keyField="year-applied"
                            control={control}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <label
                    className="required inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor="hasDegree"
                  >
                    Do you possess a Bachelor&apos;s degree?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasDegree === "Yes"}
                      value="Yes"
                      id="has-degree"
                      keyField="degree"
                      onChange={() => {
                        setFormData({ ...newFormData, hasDegree: "Yes" });
                        register("date-graduating", {
                          required: true,
                          maxLength: 256,
                        });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasDegree === "No"}
                      value="No"
                      id="has-no-degree"
                      keyField="degree"
                      onChange={() => {
                        setFormData({ ...newFormData, hasDegree: "No" });
                        unregister("date-graduating");
                      }}
                    />
                  </div>
                  <div
                    className={`mt-4 ml-4 overflow-hidden ${
                      newFormData.hasDegree === "No"
                        ? "h-auto visible opacity-100"
                        : "opacity-0 invisible h-0"
                    } flex transition-all trans-ease-all w-full top-5 right-3`}
                  >
                    {newFormData.hasDegree === "No" && (
                      <div className="flex flex-col">
                        <label
                          className="blue pl-[0.15rem] hover:cursor-pointer"
                          htmlFor="exampleCheck3"
                        >
                          Expected date of graduation:
                        </label>
                        <div className="px-1 grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-6">
                          <CustomControlledDatePicker
                            rules={{ required: true }}
                            error={errors["date-graduating"]}
                            placeholder="Month/Year"
                            views={[ "month", "year" ]}
                            label="Month/Year"
                            keyField="date-graduating"
                            control={control}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <label
                    className="required inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor="hasPassport"
                  >
                    Do you possess a current or active passport?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasPassport === "Yes"}
                      value="Yes"
                      id="has-passport"
                      keyField="passport"
                      onChange={() => {
                        setFormData({ ...newFormData, hasPassport: "Yes" });
                        register("passport-country", {
                          required: true,
                          maxLength: 256,
                        });
                        register("passport-expires", {
                          required: true,
                          maxLength: 256,
                        });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasPassport === "No"}
                      value="No"
                      id="has-no-passport"
                      keyField="passport"
                      onChange={() => {
                        setFormData({ ...newFormData, hasPassport: "No" });
                        unregister("passport-country");
                        unregister("passport-expires");
                      }}
                    />
                  </div>
                  <div
                    className={`mt-4 ml-4 overflow-hidden ${
                      newFormData.hasPassport === "Yes"
                        ? "h-auto visible opacity-100"
                        : "opacity-0 invisible h-0"
                    } flex transition-all trans-ease-all w-full top-5 right-3`}
                  >
                    {newFormData.hasPassport === "Yes" && (
                      <div className="flex flex-col">
                        <label
                          className="blue pl-[0.15rem] hover:cursor-pointer"
                          htmlFor="has-passport"
                        >
                          The country and expiration date:
                        </label>
                        <div className="px-1 grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-5 mt-2 mb-6">
                          <CustomControlledInput
                            rules={{ required: true }}
                            error={errors["passport-country"]}
                            keyField="passport-country"
                            type="text"
                            label="Country"
                            control={control}
                          />
                          <CustomControlledDatePicker
                            error={errors["passport-expires"]}
                            rules={{ required: true }}
                            placeholder="Month/Year"
                            views={[ "month", "year" ]}
                            label="Month/Year"
                            keyField="passport-expires"
                            control={control}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <label
                    className="required inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor="hasVisa"
                  >
                    Do you possess a current or active visa to live and/or work
                    in Japan?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasVisa === "Yes"}
                      value="Yes"
                      id="has-visa"
                      keyField="visa"
                      onChange={() => {
                        setFormData({ ...newFormData, hasVisa: "Yes" });
                        register("visa-type", {
                          required: true,
                          maxLength: 256,
                        });
                        register("visa-expires", {
                          required: true,
                          maxLength: 256,
                        });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasVisa === "No"}
                      value="No"
                      id="has-no-visa"
                      keyField="visa"
                      onChange={() => {
                        setFormData({ ...newFormData, hasVisa: "No" });
                        unregister("visa-type");
                        unregister("visa-expires");
                      }}
                    />
                  </div>
                  <div
                    className={`mt-4 ml-4 overflow-hidden ${
                      newFormData.hasVisa === "Yes"
                        ? "h-auto visible opacity-100"
                        : "opacity-0 invisible h-0"
                    } flex transition-all trans-ease-all w-full top-5 right-3`}
                  >
                    {newFormData.hasVisa === "Yes" && (
                      <div className="flex flex-col">
                        <label
                          className="blue pl-[0.15rem] hover:cursor-pointer"
                          htmlFor="has-visa"
                        >
                          The type of visa you currently possess:
                        </label>
                        <div className="px-1 grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-5 mt-2 mb-6">
                          <div className="input-wrapper">
                            <Controller
                              control={control}
                              name="visa-type"
                              rules={{ required: true }}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <TESelect
                                  clearBtn
                                  rules={{ required: true }}
                                  preventFirstSelection
                                  data={visaTypeOptions}
                                  label={`Visa Type`}
                                  className="styled-clear max-w-[440px] w-full required"
                                  id="visa-type"
                                  onBlur={onBlur}
                                  selected={value}
                                  onValueChange={onChange}
                                  theme={{
                                    selectInput:
                                      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear peer-focus:text-primary motion-reduce:transition-none disabled:bg-neutral-100 dark:disabled:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary cursor-pointer disabled:cursor-default required",
                                  }}
                                ></TESelect>
                              )}
                            />
                            <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">
                              {errors["visa-type"] && "This field is required."}
                            </span>
                          </div>
                          <CustomControlledDatePicker
                            error={errors["visa-expires"]}
                            rules={{ required: true }}
                            placeholder="Month/Year"
                            views={[ "month", "year" ]}
                            label="Month/Year"
                            keyField="visa-expires"
                            control={control}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <div className="flex flex-col">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-4">
                      <CustomInput
                        register={register}
                        error={errors["how-learn-about"]}
                        label="How did you learn about opportunities at AEON?"
                        keyField="how-learn-about"
                        rules={{ required: true, maxLength: 56 }}
                      />
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-6">
                      <div className="input-wrapper">
                        <Controller
                          control={control}
                          name="soonest-available"
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <TESelect
                              clearBtn
                              preventFirstSelection
                              data={soonestAvailableOptions}
                              label={`Soonest available date to depart to Japan?`}
                              className="styled-clear max-w-[440px] w-full required"
                              id="soonest-available"
                              onBlur={onBlur} // notify when input is touched/blur
                              selected={value}
                              onValueChange={onChange}
                              theme={{
                                selectInput:
                                  "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear peer-focus:text-primary motion-reduce:transition-none disabled:bg-neutral-100 dark:disabled:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary cursor-pointer disabled:cursor-default required",
                              }}
                            ></TESelect>
                          )}
                        />
                        <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">
                          {errors["soonest-available"] &&
                            "This field is required."}
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="mb-8">
                    <label
                      htmlFor="file-resume"
                      className="required mb-2 inline-block "
                    >
                      Please attach your resume/CV:{" "}
                      <span className="blue text-sm">(txt|doc|pdf|docx)</span>
                    </label>
                    <div className="input-wrapper">
                      <input
                        className="max-w-[600px] required relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
                        type="file"
                        accept=".txt, .doc, .pdf, .docx, .TXT, .DOC, .PDF, .DOCX"
                        name="file-resume"
                        id="file-resume"
                        {...register("file-resume", { required: true })}
                      />
                      <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">
                        {errors["file-resume"] && "This field is required."}
                      </span>
                    </div>
                  </div>
                  <div className="mb-8">
                    <label
                      htmlFor="file-cover"
                      className="required mb-2 inline-block "
                    >
                      Please attach your cover letter:{" "}
                      <span className="blue text-sm">(txt|doc|pdf|docx)</span>
                    </label>
                    <div className="input-wrapper">
                      <input
                        className="max-w-[600px] required relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
                        type="file"
                        accept=".txt, .doc, .pdf, .docx, .TXT, .DOC, .PDF, .DOCX"
                        name="file-cover"
                        id="file-cover"
                        {...register("file-cover", { required: true })}
                      />
                      <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">
                        {errors["file-cover"] && "This field is required."}
                      </span>
                    </div>
                  </div>
                </section>
                {/* disabled={isSubmitting || isValidating || !isValid} */}
                <button
                  className="btn btn-blue font-bold block pt-2 pb-2 px-10 w-[200px] mt-2 text-sm uppercase rounded-md trans-ease-all"
                  type="submit"
                  disabled={isSubmitting || isValidating}
                >
                  Submit Application
                </button>
              </form>
            </>
          )}
        </div>
      )}
    </article>
  );
}
