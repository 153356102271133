import { useEffect } from "react";

export const useErrorScroll = (formErrors, isValid) => {
  useEffect(() => {
    if (!isValid && formErrors && Object.keys(formErrors).length > 0) {
      const firstErrorElement = document.querySelector(".text-\\[\\#e80000\\]");

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [ formErrors, isValid ]);
};
