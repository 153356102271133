import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useHandleScroll } from "../../../hooks/useHandleScroll";

import { TEInput } from "tw-elements-react";

import { defaultBannerImages, menuLinks } from "lib/constants";
import usePage from "../../../contexts/PageContext";
import { preloadedImages } from "../../../lib/pageHelper";

const SearchWrapper = ({
  open,
  setSearchValue,
  validateSearch,
  showSearch,
  searchValue,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (showSearch || open) {
      inputRef.current?.focus();
    }
  }, [ showSearch, open ]);

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={(e) => validateSearch(e)}
      action="/"
      method="get"
      role="search"
      className={`w-full ${open ? "flex" : "hidden"} ${
        showSearch ? "md:flex" : ""
      } transition-all lg:pr-2`}
    >
      <div
        className={`search-wrapper rounded-md overflow-hidden px-1 lg:px-0 border-1 border-white lg:border-0 flex w-full items-center ${
          open ? "h-[36px]" : "h-[38px]"
        }`}
      >
        <TEInput
          type="text"
          onChange={(e) => setSearchValue(e.target.value)}
          name="s"
          id="search"
          ref={inputRef}
          placeholder="Search..."
          value={searchValue}
          className={`w-full md:!w-[154px] lg:!w-[196px] left-[-152px] peer  items-center bg-white rounded-l-md !rounded-r-none ${
            open ? "!w-full h-[32px]" : "h-[38px]"
          } pr-[32px]`}
        >
          {searchValue && (
            <span className="absolute trans-ease-all opacity-0 peer-hover:opacity-100 hover:opacity-100 hover:cursor-pointer align-middle top-[6px] right-1 ml-2 w-fit">
              <XCircleIcon
                onClick={() => setSearchValue("")}
                color="black"
                className="text-[#24292e] w-6 h-6"
              />
            </span>
          )}
        </TEInput>
        <button
          className={`btn w-[68px] min-w-[68px] ${
            open ? "h-[32px]" : "h-[38px]"
          } text-white items-center flex !rounded-l-none rounded-r-md font-semibold outline-none font-frutiger-light`}
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default function Navigation(props) {
  const { siteData, setSiteData } = usePage();

  let { search } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let searchParam = search || queryParams.get("s");

  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;

  const [ showSearch, setShowSearch ] = useState(false);
  const [ searchValue, setSearchValue ] = useState(searchParam || "");

  const [ isTighten, setIsTighten ] = useState(false);
  const [ isAnimating, setIsAnimating ] = useState(false);
  const [ menuAnimation, setMenuAnimation ] = useState(false);
  const [ showMenu, setShowMenu ] = useState(false);

  const [ curScrollDirection, curScrollPosition ] = useHandleScroll();

  const handleClick = (link) => {
    const prevLocation = siteData.prevLocation.includes("/")
      ? location.pathname.split("/").filter((item) => item !== "")
      : location.pathname;

    let newSiteData = { ...siteData };

    if (prevLocation !== link) {
      const matchingImage = defaultBannerImages.find(
        (image) => image.path === link
      );

      newSiteData.curPanelBackground = matchingImage
        ? preloadedImages[matchingImage.url]
        : null;
      newSiteData.isLoading = true;
    } else {
      newSiteData.isLoading = false;
    }

    setSiteData({ ...newSiteData });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && showMenu) {
        setShowMenu(false);
        setMenuAnimation(false);
        document.body.classList.remove("menu-open");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ showMenu ]);

  useEffect(() => {
    if (curScrollPosition > 18 && !isAnimating) {
      setIsTighten(true);
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 100);
    } else if (curScrollPosition > 1 && curScrollPosition < 8 && isTighten) {
      if (isAnimating) {
        setTimeout(() => {
          setIsTighten(false);
          setIsAnimating(false);
        }, 100);
      }
    } else if (curScrollPosition < 1) {
      setIsTighten(false);
      setIsAnimating(false);
    }
  }, [ curScrollPosition, isTighten, isAnimating ]);

  const handleBodyClick = () => {
    setShowSearch(false);
  };

  useEffect(() => {
    if (showSearch) {
      document.body.addEventListener("click", handleBodyClick);

      return () => {
        document.body.removeEventListener("click", handleBodyClick);
      };
    } else {
      document.body.removeEventListener("click", handleBodyClick);
    }
  }, [ showSearch ]);

  const validateSearch = (e) => {
    if (document.getElementById("search").value === "") {
      e.preventDefault();
    }
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    setShowSearch(!showSearch);
  };

  const handleMenuToggle = (e) => {
    e.stopPropagation();
    const newShowMenu = !showMenu;
    setShowMenu(newShowMenu);
    if (newShowMenu) {
      document.body.classList.add("mobile-menu-open");
    } else {
      document.body.classList.remove("mobile-menu-open");
    }
    setMenuAnimation(newShowMenu);
  };

  return (
    <nav
      onAnimationEnd={() => setIsAnimating(false)}
      id="header"
      className={`${
        showMenu ? "overflow-hidden" : ""
      } trans-ease-fast flex items-center ${
        props.sticky && !showMenu ? "md:sticky" : "fixed"
      } ${
        showMenu || !isTighten
          ? "mb-[8px] lg:mb-0 lg:text-base top-[0px] lg:mt-[14px] h-[40px] md:h-[56px] lg:h-[80px]"
          : "tighten h-[28px] md:h-[46px] lg:h-[54px] top-0 mt-0 mb-[40px]"
      } border-b border-[#008ae1] float-left bg-[#008de2e6] hover:bg-[#008ae1] w-full z-[100]`}
    >
      <div className="h-full container max-w-7xl xl:max-w-[1800px] mx-auto flex justify-between items-center">
        <div className={`h-full w-full flex relative items-center`}>
          <Link
            onClick={() => handleClick("/")}
            to="/"
            className="min-w-[80px] w-fit ml-1 md:ml-2 lg:ml-4 flex items-center justify-center trans-ease-fast"
          >
            <img
              className={`w-[80px]  md:w-[100px] lg:w-[128px] xl:w-[134px] ${
                showMenu || !isTighten ? "" : "lg:scale-[.92]"
              } trans-ease-fast`}
              src="/images/aeon.png"
              alt="logo"
            />
          </Link>
          <ul
            id="menu-main"
            className={`trans-ease-all leading-snug hidden md:flex h-full ${
              showMenu || !isTighten ? "" : "lg:leading-none"
            } ml-2 lg:ml-6 xl:ml-8 navbar-nav items-center gap-3 lg:gap-5 xl:gap-6 font-bold uppercase text-slate-100 font-frutiger-ce4 `}
          >
            {menuLinks.map((item, idx) => (
              <li key={idx} className="h-full nav-item ">
                <Link
                  onClick={() => handleClick(item.url)}
                  to={item.url}
                  className={`pt-1 px-2 border-b-4 border-transparent hover:border-white ${
                    item.url === `/${curLocations[0]}` ? "active " : ""
                  }text-shadow h-full flex flex-col items-center trans-ease-fast`}
                >
                  <span className="h-full flex flex-col items-start justify-center">
                    <span>{item.name[0]}</span>
                    <span>{item.name[1]}</span>
                  </span>
                </Link>
              </li>
            ))}
          </ul>

          <div className="relative flex flex-grow justify-end trans-ease-fast h-[38px] items-center">
            <div className="absolute search-wrap flex max-w-[274px] h-[38px] mr-[6px] lg:mr-[12px]">
              <div className="justify-end items-center inner-search-wrap flex w-full h-[38px]">
                <SearchWrapper
                  setSearchValue={setSearchValue}
                  validateSearch={validateSearch}
                  showSearch={showSearch}
                  searchValue={searchValue}
                />
                <button
                  onClick={(e) => handleSearch(e)}
                  className={`hidden ${
                    !showSearch ? "md:flex" : ""
                  } trans-ease-all btn min-w-[42px] text-white shadow items-center p-0 mr-2 rounded-md justify-around`}
                >
                  <MagnifyingGlassIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
            <button
              onClick={(e) => handleMenuToggle(e)}
              className={`flex md:hidden trans-ease-all btn !p-0 min-w-[32px] md:min-w-[42px] text-white shadow items-center h-[32px] w-[32px] mr-2 rounded-md justify-around`}
            >
              <Bars3Icon className="h-7 w-7" />
            </button>
          </div>
        </div>
        {showMenu && (
          <div
            id="mobile-menu"
            className="fixed top-0 inset-0 block lg:hidden h-full z-40"
            onClick={handleMenuToggle}
          >
            <ul
              className={`overflow-hidden text-left trans-ease-fast leading-snug flex flex-grow flex-col items-start ${
                menuAnimation ? "menu-container" : ""
              }`}
            >
              {menuLinks.map((item, idx) => (
                <li
                  key={idx}
                  className={`overflow-hidden font-bold uppercase text-slate-100 font-frutiger-ce4 bg-[#008de2e6] divider-dots-sidebar transition-all p-1 h-full w-full nav-item flex items-center ${
                    item.url === `/${curLocations[0]}` ? "active " : ""
                  }${menuAnimation ? `menu-item-animate` : ""}`}
                  style={{ animationDelay: `${idx * 0.1}s` }} // Staggering effect
                >
                  <Link
                    onClick={() => handleClick(item.url)}
                    to={item.url}
                    className={`overflow-hidden h-full w-full p-2 hover:bg-[#008ae1]  text-shadow flex flex-col items-start trans-ease-fast `}
                  >
                    <span className="h-full flex flex-col items-start justify-start">
                      <span>{item.name[2]}</span>
                    </span>
                  </Link>
                </li>
              ))}
              <li
                style={{ animationDelay: `${menuLinks.length * 0.1}s` }}
                className="bg-[#008de2e6] menu-item-animate divider-dots-sidebar transition-all p-1 h-full w-full nav-item flex items-center"
              >
                <SearchWrapper
                  open={true}
                  setSearchValue={setSearchValue}
                  validateSearch={validateSearch}
                  showSearch={showSearch}
                  searchValue={searchValue}
                />
              </li>
            </ul>
          </div>
        )}
      </div>
      {/* </div> */}
    </nav>
  );
}
