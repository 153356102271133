import { CalendarDaysIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { FaFileLines } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { transformImages } from "../../../lib/pageHelper";

export default function RecruitingLocationsContent({ page }) {
  const [ pageContent, setPageContent ] = useState({
    interviews: [],
  });
  //const [ newPageContent, setNewPageContent ] = useState();
  useEffect(() => {
    if (pageContent.content) {
      var imgs = document.querySelectorAll(".post-content img");
      imgs.forEach.call(imgs, function (element, index, array) {
        const floatDir = element.classList.contains("alignleft")
          ? "alignleft"
          : element.classList.contains("alignright")
          ? "alignright"
          : "aligncenter";
        element.setAttribute("loading", "lazy");
        setTimeout(function () {
          const parent = element.closest("span");
          if (parent) {
            const grandParent = parent.closest(".blue-bg-wrap");
            if (grandParent) {
              grandParent.classList.add("animated");
              if (floatDir) grandParent.classList.add(floatDir);

              parent.classList.add("animated");
              setTimeout(function () {
                element.classList.add("animated");
              }, 100);
            }
          }
        }, 150);
      });
    }
  }, [ pageContent ]);
  useEffect(() => {
    if (page.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };
      const corporateOfficeInterviews = page.children.find(
        (child) => child.node.slug === "corporate-division-interviews"
      )?.node;
      let corporateOfficeInterviewsItems =
        corporateOfficeInterviews.content.split("<ul>");

      corporateOfficeInterviewsItems.shift();
      let newFinalItems = [];
      corporateOfficeInterviewsItems.forEach((item, parentIndex) => {
        let newChildren = item.split("</li>");
        newChildren.pop();
        let newObject = {};

        newChildren.forEach((child, index) => {
          let newItem = child.replace(/<[^>]*>?/gm, "").trim();
          if (index === 0) newObject.location = newItem;
          else if (index === 1) newObject.period = newItem;
          else if (index === 2) newObject.date = newItem;
        });
        newFinalItems.push(newObject);
      });
      newPageContent.interviews = newFinalItems;
      newPageContent.content = transformImages(page.content);
      // debugger;
      setPageContent({ ...newPageContent });
    }
  }, [ page ]);

  if (!page) return null;

  return (
    <article>
      <div className="px-4 min-[992px]:px-8">
        <h1 className="max-[767px]:text-center font-frutiger-light font-extrabold uppercase text-[#24292e] text-[22px] md:text-[26px] lg:text-[30px]">
          {page.title}
        </h1>
        {pageContent.content && (
          <div
            className="mt-4 post-content blue-arrows xl:mt-8"
            dangerouslySetInnerHTML={{ __html: pageContent.content }}
          />
        )}
        <article className="recruiting-table z-50 min-h-full">
          <div id="main-office-block" data-type="main-office">
            <h2 className="uppercase font-semibold text-xl mb-2 mt-8">
              Corporate Office Interviews
            </h2>
            <div className="main-bg flex h-[47px] min-[1024px]:h-[62px] w-full items-center">
              <div className="grid w-full grid-cols-3 align-middle row">
                <div className="pl-1 pr-0 flex items-center md:pl-2 col-4 add-location text-white">
                  <MapPinIcon className="hidden sm:inline-block h-7 w-7" />
                  <span className="mr-2 pl-1 lg:pl-2 !leading-4 text-sm lg:text-[16px]">
                    Main Office
                  </span>
                </div>
                <div className="pl-1 pr-0 flex items-center md:pl-2 col-4 add-location text-white">
                  <FaFileLines className="hidden sm:inline-block h-6 w-6" />
                  <span className="mr-2 pl-1 lg:pl-2 !leading-4 text-sm lg:text-[16px]">
                    Application Period
                  </span>
                </div>
                <div className="pl-1 pr-1 flex items-center md:pl-2 col-4 add-location text-white">
                  <CalendarDaysIcon className="hidden sm:inline-block h-7 w-7" />
                  <span className="mr-2 pl-1 lg:pl-2 !leading-4 text-sm lg:text-[16px]">
                    Next Available Interview
                  </span>
                </div>
              </div>
            </div>
            {pageContent.interviews.map((interview, index) => (
              <div key={index} className="hover-wrap relative w-full">
                <div className="click-to-apply">
                  <Link
                    to={`/online-application-form?type=corporate-division&add-location=${interview.location}&add-application-period=${interview.period}&add-date=${interview.date}`}
                    className="w-full"
                  >
                    <div className="apply-spacer w-full h-[43px]"></div>
                    <span>Click to Apply</span>
                  </Link>
                </div>
                <ul className="flex w-full justify-between py-4 text-left trans-ease">
                  <li className="add-location">{interview.location}</li>
                  <li className="add-application-period">{interview.period}</li>
                  <li className="add-date">{interview.date}</li>
                </ul>
              </div>
            ))}
          </div>
        </article>
      </div>
    </article>
  );
}
