import {
  CalendarDaysIcon,
  DocumentTextIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function RecruitingLocationsContent({ page }) {
  const [ pageContent, setPageContent ] = useState({
    worldwideInterviews: null,
    worldwideInterviewsItems: null,
  });

  useEffect(() => {
    if (page.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };
      const worldwideInterviews = page.children.find(
        (child) => child.node.slug === "worldwide-interviews"
      )?.node;

      newPageContent.worldwideInterviews =
        worldwideInterviews?.content.replaceAll(
          "<ul>",
          "<ul class='flex w-full justify-between py-4 text-left'>"
        ) || null;

      setPageContent({ ...newPageContent });
    }
  }, [ page ]);

  if (!page) return null;

  return (
    <div className="px-4 min-[992px]:px-8">
      <h1 className="max-[767px]:text-center font-frutiger-light font-extrabold uppercase text-[#24292e] text-[22px] md:text-[26px] lg:text-[30px]">
        {page.title}
      </h1>
      {page?.content && (
        <div
          className="mt-4 post-content blue-arrows xl:mt-8"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      )}
      <article className="recruiting-table z-50 min-h-full">
        <div id="worldwide-block" data-type="main-office">
          <Link
            to="/online-application-form/?type=main-office"
            className="block w-full max-w-[400px] m-auto mb-2"
          >
            <button className="btn btn-blue mb-2 py-1 lg:py-2 px-2 lg:px-4 text-base lg:text-xl h-[42px] lg:h-[56px] w-full background-blue rounded-md text-slate-300 hover:text-white font-semibold bg-opacity-80 hover:bg-opacity-100">
              APPLY NOW
            </button>
          </Link>
          <h2 className="uppercase font-semibold text-base lg:text-xl mt-4 mb-2">
            Upcoming Interviews
          </h2>
          <div className="main-bg flex h-[62px] w-full items-center">
            <div className="grid w-full grid-cols-3 align-middle row">
              <div className="pl-1 pr-0 flex items-center md:pl-2 col-4 add-location text-white">
                <MapPinIcon className="hidden sm:inline-block h-7 w-7" />
                <span className="mr-2 pl-1 lg:pl-2 !leading-4 text-sm lg:text-[16px]">
                  Interview Location
                </span>
              </div>
              <div className="pl-1 pr-0 flex items-center md:pl-2 col-4 add-location text-white">
                <CalendarDaysIcon className="-mt-1 hidden sm:inline-block h-7 w-7" />
                <span className="mr-2 pl-1 lg:pl-2 !leading-4 text-sm lg:text-[16px]">
                  Date of Interview
                </span>
              </div>
              <div className="pl-1 pr-0 flex items-center md:pl-2 col-4 add-location text-white">
                <DocumentTextIcon className="hidden sm:inline-block h-7 w-7" />
                <span className="mr-2 pl-1 lg:pl-2 !leading-4 text-sm lg:text-[16px]">
                  Deadline For Application
                </span>
              </div>
            </div>
          </div>
          {pageContent.worldwideInterviews && (
            <div
              className="interview-locations"
              dangerouslySetInnerHTML={{
                __html: pageContent.worldwideInterviews,
              }}
            />
          )}
        </div>
        <Link
          to="/online-application-form?type=main-office"
          className="block h-[42px] lg:h-[56px] w-full max-w-[400px] m-auto mb-1 lg:mb-2"
        >
          <button className="btn btn-blue mb-1 lg:mb-2 py-1 lg:py-2 px-2 lg:px-4 text-base lg:text-xl h-[42px] lg:h-[56px] w-full background-blue rounded-md text-slate-300 hover:text-white font-semibold bg-opacity-80 hover:bg-opacity-100">
            APPLY NOW
          </button>
        </Link>
      </article>
    </div>
  );
}
