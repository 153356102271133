import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import usePage from "../contexts/PageContext";

import { gql, useQuery } from "@apollo/client";
import { ADD_LOCATIONS_WITH_CONTENT } from "../lib/graphql";

import MetaHead from "../components/shared/MetaHead";

import { siteUrl } from "lib/constants";
import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import DefaultSideContent from "../components/shared/layouts/DefaultSideContent";

export default function SearchPage({ searchParam }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { siteData, setSiteData } = usePage();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ searchData, setSearchData ] = useState(null);
  const [ visibleItems, setVisibleItems ] = useState({});

  let pageParam = queryParams.get("page");

  const [ pagination, setPagination ] = useState({
    page: pageParam || 1,
    total: 0,
    totalPages: 1,
    pageSize: 10,
    type: "",
  });

  const seoObject = {
    modified: "",
    link: `${siteUrl}/search`,
    seo: {
      __typename: "PostTypeSEO",
      metaKeywords: "",
      metaDesc: "AEON Search Results.",
      canonical: "",
      title: `Search Results: ${searchParam} | AEON`,
      opengraphUrl: `${siteUrl}/search/`,
      opengraphTitle: `Search Results: ${searchParam} | AEON`,
      opengraphType: "article",
      opengraphAuthor: "",
      opengraphDescription: "AEON Search Results.",
      opengraphImage: {
        __typename: "MediaItem",
        mediaItemUrl: `${siteUrl}/wp-content/uploads/2012/08/1.Shibuya-e13498476426571.jpg`,
        mediaDetails: {
          __typename: "MediaDetails",
          width: 2000,
          height: 318,
        },
        mimeType: "image/jpeg",
      },
      opengraphSiteName: "AEON",
      readingTime: 1,
    },
  };

  const parser = new DOMParser();
  const decodeString = (str) =>
    parser.parseFromString(str, "text/html").body.textContent;

  let curQueries = ADD_LOCATIONS_WITH_CONTENT;
  const GET_PAGE_DATA = gql`
    query getPageData {    
      ${curQueries}
    }
  `;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    skip: siteData?.locations.length > 0,
  });

  useEffect(() => {
    if (pageData && pageData?.locations) {
      let newSiteData = { ...siteData };

      setSiteData({
        ...newSiteData,
        locations: [ ...pageData.locations.edges ],
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  //   Brief usage examples

  // ?keyword=query
  // ?keyword=query&per_page=5
  // ?keyword=query&per_page=5&page=2
  // Define post type:

  // ?keyword=query&per_page=5&page=2&type=post
  // Filter by taxonomy/taxonomies:

  // ?keyword=test&tax_query[0][taxonomy]=category&tax_query[0][field]=id&tax_query[0][terms]=3
  // ?keyword=test&tax_query[relation]=AND&tax_query[0][taxonomy]=category&tax_query[0][field]=id&tax_query[0][terms]=3&tax_query[1][taxonomy]=category&tax_query[1][field]=id&tax_query[1][terms]=2
  // Exclude category via taxonomies:

  // ?keyword=test&tax_query[0][taxonomy]=category&tax_query[0][field]=id&tax_query[0][terms]=3&tax_query[0][operator]=NOT IN
  // For multilingual websites (WPML & Polylang):

  // ?keyword=query&lng=en

  // Results in order:
  // ?keyword=test&type=post&orderby=modified&order=DESC

  // ?keyword=test&type=post&orderby=modified&order=ASC
  // ?keyword=test&type=post&meta_key=some_key&orderby=meta_value|meta_value_num&order=ASC

  const fetchData = async (page = pagination.page) => {
    setIsLoading(true);

    fetch(
      `${siteUrl}/wp-json/relevanssi/v1/search?keyword=${searchParam}&per_page=${pagination.pageSize}&page=${page}`
    )
    .then((response) => {
      const total = response.headers.get("X-WP-Total");
      const totalPages = response.headers.get("X-WP-TotalPages");
      const type = response.headers.get("X-WP-Type");

      setPagination({
        pageSize: 10,
        page,
        total,
        totalPages,
        type,
      });

      if (page > 1) {
        queryParams.set("page", page);
      } else {
        queryParams.delete("page");
      }
      window.history.pushState({}, "", "?" + queryParams.toString());
      return response.json();
    })
    .then((data) => {
      setSearchData([ ...data ]);

      setVisibleItems({});

      data.forEach((item, index) => {
        setTimeout(() => {
          setVisibleItems((prev) => ({
            ...prev,
            [item.id]: true,
          }));
          // if (index === data.length - 1) {
          //   setIsLoading(false);
          // }
        }, index * 25);
      });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (searchParam && !searchData) {
      fetchData();
    }
  }, [ searchParam, searchData ]);

  const getResultsCount = () => {
    const curCount = Number(pagination.page) * Number(pagination.pageSize);
    const startCount = Number(curCount) - Number(pagination.pageSize) + 1;
    const endCount =
      Number(pagination.total) < Number(curCount)
        ? pagination.total
        : Number(curCount);

    return (
      <p className="text-sm text-gray-700">
        <span className="font-medium">
          {!isLoading && (
            <span className="text-sm text-gray-700">
              Showing <span className="font-medium">{startCount}</span> to{" "}
              <span className="font-medium">{endCount}</span> of{" "}
              <span className="font-medium">{pagination.total}</span> results
            </span>
          )}
        </span>
      </p>
    );
  };

  const loadPage = (page) => {
    setPagination({
      ...pagination,
      page,
    });
    fetchData(page);
  };

  useEffect(() => {
    const hiddenContent = document.getElementById("page-content");
    if (hiddenContent) {
      setTimeout(() => {
        hiddenContent.classList.add("animated");
      }, 50);
    }
  }, [ siteData ]);

  if (!siteData) return null;

  return (
    <div id="search-page" className="min-h-screen">
      {seoObject && <MetaHead seoObject={seoObject} />}
      <BackgroundImagePanel
        url={`${siteUrl}/wp-content/uploads/2012/08/1.Shibuya-e13498476426571.jpg`}
      />
      <main
        id="main-container"
        className="trans-ease min-h-screen w-full z-10 mt-[197px] md:mt-[208px] lg:mt-[224px] relative grid min-[768px]:grid-cols-9 min-[1200px]:grid-cols-12 max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all"
      >
        <div className="min-[768px]:col-span-3 min-[1200px]:col-span-3 side-container hidden min-[768px]:block -mt-[81px]">
          <aside
            id="secondary"
            className="sidebar sticky top-[53px] h-100 pt-[1.2rem] mb-4 px-1 bg-[#fcfcfc]"
          >
            <section className="rounded-sm sticky top-[53px] px-[1rem] min-[768px]:px-[1.35rem] min-[992px]:px-[1.5rem] min-[1366px]:px-[2rem] pt-2 min-[768px]:pt-4 min-[992px]:pt-4 min-[1366px]:pt-6 min-[1844px]:pt-5 z-50 min-h-[100px]">
              {siteData.locations && (
                <DefaultSideContent
                  locations={siteData.locations}
                  loading={isLoading}
                />
              )}
            </section>
          </aside>
        </div>
        <section className="bg-[#fcfcfc] min-[768px]:col-span-6 min-[1200px]:col-span-9 mx-auto w-full -mt-[81px] blue-arrows">
          <article>
            <div className="mb-4 p-8 pt-12 z-50 min-h-full transe-ease-all">
              <h1 className="text-center min-[768px]:text-left font-frutiger-light uppercase font-bold text-[#24292e] mb-4 min-[768px]:mb-6">
                Search Results for: {searchParam}
              </h1>
              <div className="relative w-full flex items-center justify-center top-[48px] animate-in duration-500 fade-in fade-out ease-in-out trans-ease-all">
                <div
                  className={`${
                    !isLoading && "h-0 hidden"
                  } relative trans-ease-all`}
                >
                  <div className="absolute animate-in duration-500 fade-in fade-out ease-in-out trans-ease-slow top-[100px] loading"></div>
                </div>
              </div>
              <div id="page-content" className="hideme">
                {searchData &&
                  searchData.map((item) => (
                    <section
                      key={item.id}
                      id={`post-${item.id}`}
                      className={`transition-opacity duration-500 ease-in-out ${
                        visibleItems[item.id] ? "opacity-100" : "opacity-100"
                      } border-t border-gray-200`}
                    >
                      <header className="entry-header">
                        <h2 className="mt-4 entry-title font-bold">
                          <Link to={item.link} rel="bookmark">
                            {decodeString(item.title.rendered)}
                          </Link>
                        </h2>
                      </header>

                      <div className="entry-summary">
                        <span className="excerpt_part">
                          <Link to={item.link} rel="bookmark">
                            <p
                              className="mb-4 mt-1 xl:mt-2"
                              dangerouslySetInnerHTML={{
                                __html: decodeString(item.excerpt.rendered),
                              }}
                            />
                          </Link>
                        </span>
                      </div>
                    </section>
                  ))}
              </div>
              {!isLoading && (
                <nav
                  className="transe-ease-slow flex items-center justify-between py-3 border-t border-gray-200"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">{getResultsCount()}</div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                      onClick={() => loadPage(Number(pagination.page) - 1)}
                      disabled={isLoading || pagination.page === 1}
                      className={`btn btn-blue ${
                        isLoading || pagination.page === 1
                          ? "opacity-50 cursor-default"
                          : "hover:bg-gray-50 focus-visible:outline-offset-0"
                      } relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300`}
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => loadPage(Number(pagination.page) + 1)}
                      disabled={
                        isLoading ||
                        Number(pagination.page) ===
                          Number(pagination.totalPages)
                      }
                      className={`btn btn-blue ${
                        isLoading ||
                        Number(pagination.page) ===
                          Number(pagination.totalPages)
                          ? "opacity-50 cursor-default"
                          : "hover:bg-gray-50 focus-visible:outline-offset-0"
                      } relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 `}
                    >
                      Next
                    </button>
                  </div>
                </nav>
              )}
            </div>
          </article>
        </section>
      </main>
      <div className="pb-4 side-container block min-[768px]:hidden px-2 max-w-[540px] min-[768px]:max-w-[758px] m-auto">
        <DefaultSideContent
          locations={siteData.locations}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
