import React from "react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
} from "tw-elements-react";

export default function ModalDefault3({
  options,
  children,
  title,
  size = "lg",
  showModal,
  setShowModal,
  onHide,
  addClasses = "",
}) {
  const animationDirection = {
    top: {
      show: "translate-y-0 opacity-100",
      hidden: "-translate-y-[100%] opacity-0",
    },
    "top-right": {
      show: "translate-x-0 opacity-100",
      hidden: "translate-x-[100%] opacity-0",
    },
    bottom: {
      show: "translate-x-0 opacity-100",
      hidden: "-translate-x-[100%] opacity-0",
    },
  };

  return (
    <TEModal
      className="right-0 p-2"
      show={showModal}
      setShow={setShowModal}
      onHide={() => onHide()}
    >
      <TEModalDialog
        position={options.position || "center"}
        theme={{ ...animationDirection[options.position] }}
        size={size}
      >
        <TEModalContent className={addClasses + " rounded-none"}>
          {title && (
            <TEModalHeader className="border-none p-0">
              <h5 className="text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] leading-normal">
                {title}
              </h5>
              <button
                type="button"
                className="rounded btn btn-blue p-1 text-white box-content hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
          )}
          <TEModalBody className="text-sm !pt-0 post-content text-left">
            {children}
          </TEModalBody>
        </TEModalContent>
      </TEModalDialog>
    </TEModal>
  );
}
