import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import usePage from "../../../contexts/PageContext";
import Footer from "./Footer";
import Navigation from "./Navigation";

export default function MainWrapper({ children }) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();
  const previousLocation = useRef(location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      const pageContent = document.getElementById("page-content");

      if (pageContent) {
        if (previousLocation.current !== location.pathname) {
          // console.log("Route changed, removing animated class");
          pageContent.classList.remove("animated");
          previousLocation.current = location.pathname; // Update the stored location
        } else {
          //console.log("Same route, skipping");
        }
      }
    };

    handleRouteChange();
  }, [ location.pathname ]);

  useEffect(() => {
    setSiteData({ ...siteData, isLoading: true });
  }, []);

  useEffect(() => {
    if (!location.hash) {
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollPosition > 18) {
        window.scrollTo({
          top: 18,
          behavior: "auto",
        });
      }
    }
  }, [ location ]);

  return (
    <div
      id="main-wrapper"
      className="container-fluid mx-auto relative flex flex-col min-h-screen h-full bg-[#fcfcfc]"
    >
      <a className="skip-link screen-reader-text" href="#main-container">
        Skip to content
      </a>
      <Navigation sticky={true} />
      {children}
      <Footer />
    </div>
  );
}
