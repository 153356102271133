import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import ChildrenNavigation from "../components/shared/layouts/ChildrenNavigation";
import PageContent from "../components/shared/layouts/PageContent";
import PageNavigation from "../components/shared/layouts/PageNavigation";

import { gql, useQuery } from "@apollo/client";
import usePage from "../contexts/PageContext";
import { ADD_LOCATIONS_WITH_CONTENT, ADD_PAGES } from "../lib/graphql";

export default function LocationPage(props) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1].replace("/", "");

  let curQueries = ADD_PAGES;
  if (!siteData?.locations || !siteData.locations.length > 0)
    curQueries = curQueries + ADD_LOCATIONS_WITH_CONTENT;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.locations && siteData.locations.length > 0,
  });

  useEffect(() => {
    if (pageData && pageData.locations) {
      let newSiteData = { ...siteData };

      let locations = pageData?.locations?.edges || [];
      if (locations.length > 0) newSiteData.locations = locations;
      const newPage = locations.find(
        (location, idx) => location.node.slug === slugId
      )?.node;
      const featuredImage = newPage?.featuredImage?.node || null;
      const hasParent = newPage?.staffInformation?.region || null;

      if (hasParent && !featuredImage?.mediaItemUrl) {
        const parentFeaturedImage = hasParent?.featuredImage?.node || null;
        if (parentFeaturedImage?.mediaItemUrl)
          newSiteData.curPanelBackground = parentFeaturedImage.mediaItemUrl;
      } else {
        if (featuredImage?.mediaItemUrl)
          newSiteData.curPanelBackground = featuredImage.mediaItemUrl;
      }

      let curChildren = locations.filter(
        (location, idx) =>
          location?.node?.staffInformation?.region?.title === hasParent?.title
      );

      if (!siteData?.locations || !siteData.locations.length > 0)
        newSiteData.locations = locations;

      newSiteData.pages = {
        ...siteData.pages,
        [curLocation]: { ...newPage },
      };
      const index =
        curChildren.findIndex((location) => location?.node?.slug === slugId) ||
        0;
      newSiteData.prevPage = curChildren[index - 1] || null;
      newSiteData.nextPage = curChildren[index + 1] || null;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curSlug: slugId,
        curParent: hasParent,
        curChildren: curChildren,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage =
    siteData?.locations.find((location, idx) => location.node.slug === slugId)
      ?.node || null;
  const parentPage = curPage?.staffInformation?.region || null;

  useEffect(() => {
    if (curPage) {
      if (curPage && !curPage?.seo) {
        console.log("missing SEO!!! curPage", curPage);
      }

      let newSiteData = { ...siteData };
      let locations = siteData?.locations || [];
      let newPage = locations.find(
        (location, idx) => location.node.slug === slugId
      )?.node;

      const featuredImage = newPage?.featuredImage?.node || null;
      const hasParent = newPage?.staffInformation?.region || null;
      const parentSlug = hasParent?.nodes[0]?.slug || null;

      let curChildren = locations.filter((location, idx) => {
        const childSlug =
          location.node.staffInformation.region.nodes[0].slug || null;
        return childSlug === parentSlug;
      });

      if (hasParent && !featuredImage?.mediaItemUrl) {
        const parentFeaturedImage =
          hasParent?.featuredImage?.node ||
          hasParent?.nodes[0]?.featuredImage?.node ||
          null;
        if (parentFeaturedImage?.mediaItemUrl)
          newSiteData.curPanelBackground = parentFeaturedImage.mediaItemUrl;
      } else {
        if (featuredImage?.mediaItemUrl)
          newSiteData.curPanelBackground = featuredImage.mediaItemUrl;
      }
      const index =
        curChildren.findIndex((location) => location?.node?.slug === slugId) ||
        0;
      newSiteData.prevPage = curChildren[index - 1] || null;
      newSiteData.nextPage = curChildren[index + 1] || null;

      setSiteData({
        ...newSiteData,
        curParent: hasParent.nodes[0],
        curChildren: curChildren,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <div id="location-page" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}

          <BackgroundImagePanel url={siteData.curPanelBackground} />
          <main
            id="main-container"
            className="trans-ease min-h-screen w-full z-10 mt-[197px] md:mt-[208px] lg:mt-[224px] relative grid min-[768px]:grid-cols-9 min-[1200px]:grid-cols-12 max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all"
          >
            <div className="min-[768px]:col-span-3 side-container hidden min-[768px]:block -mt-[81px]">
              <PageNavigation
                size="full"
                page={curPage}
                parent={siteData.curParent}
                children={siteData.curChildren}
              />
            </div>
            <section className="bg-[#fcfcfc] min-[768px]:col-span-6 min-[1200px]:col-span-9 mx-auto w-full -mt-[81px] blue-arrows">
              <PageContent page={curPage} />
              {curPage &&
                (siteData?.prevPage?.node?.title ||
                  siteData?.nextPage?.node?.title) && (
                  <ChildrenNavigation
                    parentSlug="location"
                    curPage={curPage}
                    parentPage={parentPage}
                    siteData={siteData}
                  />
                )}
            </section>
          </main>
          <div className="pb-4 side-container block min-[768px]:hidden px-2 max-w-[540px] min-[768px]:max-w-[758px] m-auto">
            <PageNavigation
              size="full"
              page={curPage}
              parent={siteData.curParent}
              children={siteData.curChildren}
            />
          </div>
        </>
      )}
    </div>
  );
}
