import { GetTheFaqs, SubmitApplication } from "lib/pageHelper";
import Locations from "../../Locations";

export default function DefaultSideContent({ loading, locations = [] }) {
  return (
    <div id="side-content" className={`${loading ? "opacity-0" : ""}`}>
      <SubmitApplication />
      <GetTheFaqs />
      <Locations locations={locations} position="side" />
    </div>
  );
}
