import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import DropdownContent from "../components/shared/layouts/DropdownContent";
import PageNavigation from "../components/shared/layouts/PageNavigation";
import MetaHead from "../components/shared/MetaHead";

import { gql, useQuery } from "@apollo/client";
import { stripHTML } from "lib/pageHelper";
import usePage from "../contexts/PageContext";
import {
  ADD_LOCATIONS_WITH_CONTENT,
  ADD_PAGES_PARENT_CHILDREN2,
} from "../lib/graphql";

export default function CareerOpportunities(props) {
  const { siteData, setSiteData } = usePage();
  const parentSlugId = "life-at-aeon";

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = !siteData?.locations?.length
    ? ADD_PAGES_PARENT_CHILDREN2 + ADD_LOCATIONS_WITH_CONTENT
    : ADD_PAGES_PARENT_CHILDREN2; //this might use w content

  const GET_PAGE_DATA = gql`
    query getPageData($id: String!, $parent: ID!) {    
      ${curQueries}
    }
  `;
  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId, parent: parentSlugId },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let curParent;
      let newPage;

      if (pageData.pages?.nodes?.length > 1 && parentSlugId) {
        newPage = pageData.pages.nodes.filter(
          (page) => page?.parent?.node?.slug !== parentSlugId
        )[0];
        curParent = pageData.pages.nodes.filter(
          (page) => page?.parent?.node?.slug === parentSlugId
        )[0]?.parent?.node;
      } else {
        newPage = pageData.pages?.nodes[0] || {};
      }

      const featuredImage = newPage?.featuredImage?.node || null;

      const newPageContent = newPage.content
      .replaceAll("<h2>", "|<h2>")
      .split("|");
      newPageContent.shift();
      const newContent = [];

      newPageContent.forEach((item, idx) => {
        const newItems = item.replace("</h2>", "</h2>|").split("|");
        newContent.push({
          question: stripHTML(newItems[0]),
          answer: newItems[1],
        });
      });
      newSiteData.curPanelBackground = featuredImage?.mediaItemUrl || null;

      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: {
          // we store the object as whole location.path - '/'
          ...newPage,
          featuredImage: featuredImage,
          pageContent: newContent,
        },
      };

      newSiteData.prevPage = null;
      newSiteData.nextPage = null;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curParent: curParent,
        curChildren: curParent.children.nodes || null,
        locations: pageData?.locations?.edges || siteData.locations || [],
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[slugId];

  useEffect(() => {
    if (curPage) {
      if (curPage && !curPage?.seo) {
        console.log("missing SEO!!! curPage", curPage);
      }

      let newSiteData = { ...siteData };

      const featuredImage = curPage?.featuredImage?.node || null;

      if (featuredImage?.mediaItemUrl)
        newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      setSiteData({
        ...newSiteData,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <div id="careers" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}
          <BackgroundImagePanel url={siteData.curPanelBackground} />
          <main
            id="main-container"
            className=" min-h-full w-full z-10 mt-[224px] relative grid grid-cols-4 min-[576px]:max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all"
          >
            <div className="side-container hidden lg:block -mt-[81px]">
              <PageNavigation
                size="full"
                page={curPage}
                parent={siteData.curParent}
                children={siteData.curChildren}
              />
            </div>
            <section className="bg-[#fcfcfc] col-span-4 min-[1024px]:col-span-3 mx-auto w-full -mt-[81px]">
              <DropdownContent htmlAnswer={true} page={curPage} />
            </section>
          </main>
          <div className="side-container block lg:hidden px-2 max-w-[540px] min-[768px]:max-w-[758px] m-auto">
            <PageNavigation
              size="full"
              page={curPage}
              parent={siteData.curParent}
              children={siteData.curChildren}
            />
          </div>
        </>
      )}
    </div>
  );
}
