import { useEffect } from "react";
import { Link } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import usePage from "../contexts/PageContext";
import { ADD_HOME_REGIONS } from "../lib/graphql";

import { regionsData } from "lib/constants";
import InterviewLocations from "./InterviewLocations";
import Locations from "./Locations";

const CustomLink = ({ to, label, title, addClass = "" }) => {
  return (
    <div className="button-wrapper w-fit mt-2">
      <Link
        className={`${addClass} trans-ease-all arrow-link group flex items-center justify-items-center w-ful text-center font-bold border-transparent hover:border-[#33a1e7] border-b-2`}
        title={title}
        to={to}
      >
        <span className="mr-1">{label}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={4}
          stroke="#33a1e7"
          className="blue w-3 h-3 mb-[.1rem]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={4}
          stroke="#33a1e7"
          className="trans-ease-all opacity-0 group-hover:opacity-100 ml-[-6px] w-3 h-3 mb-[.1rem]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </Link>
    </div>
  );
};

export default function HomePageContent({ page }) {
  const { siteData, setSiteData } = usePage();

  let curQueries2 = ADD_HOME_REGIONS;

  const GET_PAGE_DATA2 = gql`
    query getPageData2 {    
      ${curQueries2}
    }
  `;

  const { data: pageData } = useQuery(GET_PAGE_DATA2);

  useEffect(() => {
    if (pageData) {
      let newRegionsData = [];

      const regionsContent = pageData?.pages?.edges;

      regionsContent.forEach((region, idx) => {
        let imageTagStr = region.node.content.match(
          /<img\s+[^>]*src="([^"]*)"[^>]*>/i
        );

        newRegionsData.push({
          pageId: region.node.pageId,
          image: imageTagStr,
          excerpt: region.node.excerpt,
          title: region.node.title,
          link: regionsData[region.node.pageId],
        });
      });
      setSiteData({ ...siteData, regions: [ ...newRegionsData ] });
      setTimeout(function () {
        const hiddenContent = document.getElementById("page-content");
        if (hiddenContent) hiddenContent.classList.add("animated");
      }, 100);
      setTimeout(function () {
        const hiddenContent = document.getElementById("regions-content");
        if (hiddenContent) hiddenContent.classList.add("animated");
      }, 200);
    }
  }, [ pageData ]);

  if (!siteData) return null;

  const { content } = page;

  const newContent2 = content.split("</article>");
  const whyAeon = newContent2[0].replace(/<article.*?>/gi, "");

  const careers =
    newContent2[1] && newContent2[1].includes("<article")
      ? newContent2[1].replace(/<article.*?>/gi, "")
      : newContent2[1];
  const careersArr = careers
    ? careers.split('<div id="recruiting-slideshow">')
    : null;

  const careersContentStart = careersArr ? careersArr[0] : "";
  const careersContentEnd = careersArr ? careersArr[1] : "";

  return (
    <>
      <section
        id="page-content"
        className="hideme px-2 w-full grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-12 max-w-[540px] lg:max-w-full m-auto trans-ease-all mb-4"
      >
        <article
          id="why-aeon"
          className="min-h-[338px] flex flex-col w-100 flex-grow pt-1"
        >
          <h2 className="home-why-aeon bg-[length:36px] lg:bg-[length:52px] xl:bg-[length:62px] w-full text-[18px] lg:text-[22px] xl:text-[24px] h-[32px] lg:h-[48px] xl:h-[58px] border-b-[3px] lg:border-b-4 border-[#008ae1b3] font-semibold pl-[38px] lg:pl-[52px] xl:pl-[62px] self-center flex pt-1 lg:pt-3 ">
            WHY AEON
          </h2>
          <p
            className="text-justify mt-2 mb-2"
            dangerouslySetInnerHTML={{ __html: whyAeon }}
          />
          <CustomLink
            to="/about-aeon"
            label="MORE ABOUT AEON"
            title="Messages from the AEON Staff"
          />
        </article>
        <article
          id="careers"
          className="min-h-[338px] flex flex-col w-100 flex-grow pt-1"
        >
          <h2 className="home-careers bg-[length:37px] lg:bg-[length:53px] xl:bg-[length:62px] w-full text-[18px] lg:text-[22px] xl:text-[24px] h-[32px] lg:h-[48px] xl:h-[58px] border-b-[3px] lg:border-b-4 border-[#008ae1b3] font-semibold pl-[42px] lg:pl-[62px] xl:pl-[72px] self-center flex pt-1 lg:pt-3">
            CAREERS
          </h2>
          <p
            id="home-recruiting-locations"
            className=" text-center mt-3"
            dangerouslySetInnerHTML={{ __html: careersContentStart }}
          />
          <InterviewLocations />
          <div
            className="flex-grow text-center"
            dangerouslySetInnerHTML={{ __html: careersContentEnd }}
          />
          <CustomLink
            to="/recruiting-locations"
            label="VIEW ALL RECRUITING LOCATIONS"
            title="AEON Recruiting Locations"
          />
        </article>
        <article
          id="from-our-staff"
          className="min-h-[338px] flex flex-col w-100 flex-grow pt-1"
        >
          <h2 className="home-from-our-staff bg-[length:35px] lg:bg-[length:52px] xl:bg-[length:62px] w-full text-[18px] lg:text-[22px] xl:text-[24px] h-[32px] lg:h-[48px] xl:h-[58px] border-b-[3px] lg:border-b-4 border-[#008ae1b3] font-semibold pl-[36px] lg:pl-[52px] xl:pl-[62px] self-center flex pt-1 lg:pt-3">
            FROM OUR STAFF
          </h2>
          <Locations locations={siteData.locations} position="home" />
          <CustomLink
            to="/testimonials-from-our-staff"
            label="MORE MESSAGES"
            title="Messages from the AEON Staff"
          />
        </article>
      </section>
      {siteData?.regions && (
        <section
          id="regions-content"
          className="hideme max-w-[540px] lg:max-w-full mx-auto mb-4 lg:mb-8 post-content w-full grid grid-cols-1 lg:grid-cols-2 min-[1200px]:grid-cols-3 gap-8"
        >
          {siteData.regions.map((region, idx) => {
            return (
              <div
                key={idx}
                className="flex flex-col w-full h-full p-4 pb-1 border border-gray-300 bg-gray-200 rounded-sm hover:border-gray-350 hover:shadow-md hover:drop-shadow-2xl transition-all"
              >
                <h2 className="w-full font-thin text-xl bg-[#1995e4] bg-opacity-80 border-t-4 border-b-4 border-[#4cadea] flex items-center text-white justify-center uppercase">
                  {region.title}
                </h2>
                <div className="flex flex-col h-full mt-1 justify-between">
                  <div className="h-full pt-2">
                    <div className="flex float-left w-[147px] items-center mr-5 border-[3px] border-gray-300 rounded-lg">
                      <img
                        className="rounded-md"
                        src={region.image[1]}
                        alt={region.image[1]}
                      />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: region.excerpt }} />
                  </div>
                  <div className="!text-[#33a1e7] font-semibold w-fit">
                    <CustomLink
                      to={`/location/${region.link}`}
                      label="LEARN MORE"
                      addClass="!blue"
                      title={`Learn more about ${region.title}`}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      )}
    </>
  );
}
