import { HoverArrow, StaticArrow } from "lib/pageHelper";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ to, label, title, direction }) => {
  return (
    <div
      className={`button-wrapper w-fit ${
        direction === "left" ? "ml-8" : "mr-8"
      }`}
    >
      <Link
        className="trans-ease-all arrow-link group flex items-center uppercase text-sm font-semibold  hover:text-[#0056b3]  blue border-transparent hover:border-[#33a1e7] border-b-2"
        title={title}
        to={to}
      >
        {direction === "right" && (
          <>
            <span className="">{label}</span>
            <StaticArrow direction={direction} />
            <HoverArrow direction={direction} />
          </>
        )}
        {direction === "left" && (
          <>
            <HoverArrow direction={direction} />
            <StaticArrow direction={direction} />
            <span className="">{label}</span>
          </>
        )}{" "}
      </Link>
    </div>
  );
};

export default function ChildrenNavigation({
  curPage,
  parentPage,
  siteData,
  parentSlug = "",
}) {
  parentSlug =
    parentSlug || (parentPage?.slug ? parentPage?.slug : curPage?.slug);

  let prevTitle = null;
  let prevSlug = null;
  let prevLink = null;

  if (curPage?.slug !== parentSlug) {
    prevTitle = siteData?.prevPage?.title || siteData?.prevPage?.node?.title;
    prevSlug = siteData?.prevPage?.slug || siteData?.prevPage?.node?.slug;
    prevLink = `/${parentSlug}/${prevSlug}`;
  }

  const nextTitle =
    siteData?.nextPage?.title || siteData?.nextPage?.node?.title;
  const nextSlug = siteData?.nextPage?.slug || siteData?.nextPage?.node?.slug;
  const nextLink = `/${parentSlug}/${nextSlug}`;
  useEffect(() => {
    const hiddenContent = document.getElementById("children-navigation");
    if (hiddenContent) {
      setTimeout(() => {
        hiddenContent.classList.add("animated");
      }, 50);
    }
  }, [ curPage ]);
  return (
    <div
      id="children-navigation"
      className="hideme relative block w-full mt-2 mb-4"
    >
      <div className="flex items-center justify-between w-full">
        <div className="absolute left-0 items-center justify-start">
          {prevTitle && (
            <CustomLink
              direction="left"
              to={prevLink}
              label={prevTitle}
              title={prevTitle}
            />
          )}
        </div>
        <div className="absolute right-0 items-center justify-end">
          {nextTitle && (
            <CustomLink
              direction="right"
              to={nextLink}
              label={nextTitle}
              title={nextTitle}
            />
          )}
        </div>
      </div>
    </div>
  );
}
