import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import ChildrenNavigation from "../components/shared/layouts/ChildrenNavigation";
import PageContent from "../components/shared/layouts/PageContent";
import PageNavigation from "../components/shared/layouts/PageNavigation";

import { gql, useQuery } from "@apollo/client";
import usePage from "../contexts/PageContext";
import {
  ADD_LOCATIONS_WITH_CONTENT,
  ADD_PAGES,
  ADD_PAGES_PARENT_CHILDREN,
  ADD_PAGES_PARENT_CHILDREN2,
} from "../lib/graphql";

export default function SideNavPage(props) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1];
  const parentSlugId = curLocations[0].replace("/", "") || null; // watch out for any with 3 segments
  const curParent =
    curLocations.length > 1 ? siteData.pages[parentSlugId] || null : null;

  let GET_PAGE_DATA;

  if (curParent && curLocations.length < 2) {
    let curQueries = !siteData?.locations?.length
      ? ADD_PAGES + ADD_LOCATIONS_WITH_CONTENT
      : ADD_PAGES;

    GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;
  } else if (!curParent && !parentSlugId) {
    let curQueries = !siteData?.locations?.length
      ? ADD_PAGES_PARENT_CHILDREN + ADD_LOCATIONS_WITH_CONTENT
      : ADD_PAGES_PARENT_CHILDREN;

    GET_PAGE_DATA = gql`
    query getPageData($id: String!) {    
      ${curQueries}
    }
  `;
  } else {
    let curQueries = !siteData?.locations?.length
      ? ADD_PAGES_PARENT_CHILDREN2 + ADD_LOCATIONS_WITH_CONTENT
      : ADD_PAGES_PARENT_CHILDREN2;

    GET_PAGE_DATA = gql`
    query getPageData($id: String!, $parent: ID!) {    
      ${curQueries}
    }
  `;
  }
  //TODO: add a children loader here same way w skip, then can show page while children are loading
  //TODO: this would be lightning fast page loading instead of how it is now, loading all at once

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId, parent: parentSlugId },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      if (!siteData.locations.length && pageData?.locations?.length) {
        siteData.locations = pageData.locations;
      }
      let newSiteData = { ...siteData };
      let newPage;

      if (pageData.pages?.nodes?.length > 1 && parentSlugId) {
        newPage = pageData.pages.nodes.filter(
          (page) => page?.parent?.node?.slug === parentSlugId
        )[0];
      } else {
        newPage = pageData.pages?.nodes[0] || {};
      }

      const featuredImage = newPage?.featuredImage?.node || null;
      const hasParent = curParent || newPage.parent?.node || null;

      if (hasParent && !siteData?.pages[parentSlugId]) {
        newSiteData.pages[parentSlugId] = hasParent;
      }
      let curChildren;

      if (hasParent) {
        curChildren = hasParent?.children?.nodes || [];

        const parentFeaturedImage = hasParent?.featuredImage?.node || null;

        if (parentFeaturedImage?.mediaItemUrl)
          newSiteData.curPanelBackground = parentFeaturedImage.mediaItemUrl;

        // be careful here some childs have images in locations
        newSiteData.pages = {
          ...siteData.pages,
          [curLocation]: {
            ...hasParent,
            children: curChildren,
            featuredImage: parentFeaturedImage,
          },
        };
      } else {
        curChildren = newPage.children?.edges || [];

        if (featuredImage?.mediaItemUrl)
          newSiteData.curPanelBackground = featuredImage.mediaItemUrl;
      }

      let newChildren = {};
      if (curChildren && curChildren.length > 0) {
        curChildren.forEach((child) => {
          newChildren[`${parentSlugId}/${child?.node?.slug || child?.slug}`] =
            child?.node || child;
        });
      }
      if (featuredImage?.mediaItemUrl)
        newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      if (siteData.curChildren !== curChildren) {
        newSiteData.curChildren = curChildren?.nodes || curChildren;
      }

      const newPageData = {
        ...newPage,
        content: newPage.content.replace(
          '<img decoding="async"',
          '<img decoding="async" loading="lazy"'
        ),
        children: curChildren,
        featuredImage: featuredImage,
      };

      newSiteData.pages = {
        ...siteData.pages,
        ...newChildren,
        [curLocation]: newPageData,
      };
      newSiteData.curParent = hasParent;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        locations: pageData?.locations?.edges || siteData.locations || [],
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  let curPage = siteData?.pages[curLocation];

  const parentPage =
    curPage?.parent?.node ||
    siteData?.pages[parentSlugId] ||
    curPage?.curParent ||
    [];
  const curChildren = parentPage?.children
    ? parentPage.children?.nodes || parentPage.children
    : curPage?.children?.nodes || curPage?.children || [];

  useEffect(() => {
    if (curPage) {
      if (curPage && !curPage?.seo) {
        console.log("missing SEO!!! curPage", curPage);
      }
      let newSiteData = { ...siteData };

      const featuredImage =
        curPage?.featuredImage || parentPage?.featuredImage || null;
      if (featuredImage?.mediaItemUrl || featuredImage?.node?.mediaItemUrl)
        newSiteData.curPanelBackground =
          featuredImage.mediaItemUrl || featuredImage?.node?.mediaItemUrl;

      newSiteData.curChildren = curChildren;
      newSiteData.prevPage = null;
      newSiteData.nextPage = curChildren[0]?.node || curChildren[0];

      curChildren.forEach((item, index) => {
        if ((item?.node?.slug || item?.slug) === slugId) {
          const prev = index > 0 ? index - 1 : null;
          const next = index <= curChildren.length ? index + 1 : null;

          if (index === 0) {
            newSiteData.prevPage =
              siteData?.curParent?.node ||
              curPage?.parent?.node ||
              curPage?.parent ||
              null;
          } else {
            newSiteData.prevPage = curChildren[prev]?.node || curChildren[prev];
          }
          newSiteData.nextPage = curChildren[next]?.node || curChildren[next];
        }
      });

      if (curPage?.slug === parentSlugId) {
        newSiteData.prevPage = curPage;
      }

      if (parentPage && !newSiteData.prevPage) {
        newSiteData.prevPage = parentPage;
      }

      setSiteData({
        ...newSiteData,
        curParent: parentPage || curPage,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  // useEffect(() => {
  //   const hiddenContent = document.getElementById("page-content");
  //   if (hiddenContent) {
  //     setTimeout(() => {
  //       console.log("adding animated to sidenav page1");
  //       hiddenContent.classList.add("animated");
  //     }, 50);
  //   }
  // }, [ curPage, pageData ]);

  if (!siteData) return null;

  return (
    <div id="side-nav-page" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && (
            <MetaHead
              seoObject={curPage}
              addMap={!!curPage?.googleMaps?.latitude}
            />
          )}
          <BackgroundImagePanel url={siteData.curPanelBackground} />
          <main
            id="main-container"
            className="trans-ease min-h-screen w-full z-10 mt-[197px] md:mt-[208px] lg:mt-[224px] relative grid min-[768px]:grid-cols-9 min-[1200px]:grid-cols-12 max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px]"
          >
            <div className="min-[768px]:col-span-3 side-container hidden min-[768px]:block -mt-[81px]">
              <PageNavigation
                size="full"
                page={curPage}
                parent={siteData.curParent}
                children={siteData.curChildren}
              />
            </div>
            <section className="bg-[#fcfcfc] min-[768px]:col-span-6 min-[1200px]:col-span-9 mx-auto w-full -mt-[81px] blue-arrows">
              <PageContent page={curPage} />
              {(siteData?.prevPage?.title || siteData?.nextPage?.title) && (
                <ChildrenNavigation
                  curPage={curPage}
                  parentPage={parentPage}
                  siteData={siteData}
                />
              )}
            </section>
          </main>
          <div className="pb-4 side-container block min-[768px]:hidden px-2 max-w-[540px] min-[768px]:max-w-[758px] m-auto">
            <PageNavigation
              size="full"
              page={curPage}
              parent={siteData.curParent}
              children={siteData.curChildren}
            />
          </div>
        </>
      )}
    </div>
  );
}
