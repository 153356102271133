import { defaultBannerImages, siteUrl } from "lib/constants";
import { Link, useLocation } from "react-router-dom";
import usePage from "../../../contexts/PageContext";
import { preloadedImages } from "../../../lib/pageHelper";

export default function Footer() {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const handleClick = (link) => {
    const currentPath =
      location.pathname === "/" ? "/" : location.pathname.replace(/\/$/, "");
    const targetPath = link === "/" ? "/" : link.replace(/\/$/, "");

    if (currentPath === targetPath) {
      return;
    }

    const prevLocation = siteData.prevLocation.includes("/")
      ? location.pathname.split("/").filter((item) => item !== "")
      : location.pathname;

    let newSiteData = { ...siteData };

    if (prevLocation !== link) {
      const matchingImage = defaultBannerImages.find(
        (image) => image.path === link
      );

      newSiteData.curPanelBackground = matchingImage
        ? preloadedImages[matchingImage.url]
        : null;
      newSiteData.isLoading = true;
    } else {
      newSiteData.isLoading = false;
    }

    setSiteData({ ...newSiteData });
  };

  return (
    <footer
      id="footer"
      className="bg-[#e1e3e5] trans-ease-all min-[992px]:h-[92px] w-full flex items-center z-50 shadow relative py-4"
    >
      <div className="container max-w-[1600px] px-6 max-[767px]:max-w-[466px] mx-auto flex flex-col min-[768px]:flex-row items-center justify-between text-[12px] md:text-md text-[#3b3b3b] font-frutiger-roman uppercase">
        <ul
          id="menu-footer"
          className="min-[768px]:h-[36px] flex items-center flex-col min-[768px]:flex-row list-none min-[768px]:divider-line flex-grow w-full"
        >
          <li className="flex items-center h-full w-full min-[768px]:w-auto">
            <Link
              to="/testimonials-from-our-staff"
              onClick={() => handleClick("/testimonials-from-our-staff")}
              className="w-fit border-b border-transparent hover:border-[#a2a2a2] mr-0 min-[768px]:mr-3"
            >
              From our staff
            </Link>
          </li>
          <li className="flex items-center h-full w-full min-[768px]:w-auto">
            <Link
              to="/application-requirements/contract-stipulations-benefits"
              onClick={() =>
                handleClick(
                  "/application-requirements/contract-stipulations-benefits"
                )
              }
              className="w-fit border-b border-transparent hover:border-[#a2a2a2] mx-0 min-[768px]:mx-3"
            >
              <span className="min-[768px]:hidden">
                Contract Stipulations & Benefits
              </span>
              <span className="hidden min-[768px]:flex">
                Contract Stipulations
                <br />& Benefits
              </span>
            </Link>
          </li>
          <li className="flex items-center h-full w-full min-[768px]:w-auto">
            <Link
              to="/frequently-asked-questions"
              onClick={() => handleClick("/frequently-asked-questions")}
              className="w-fit border-b border-transparent hover:border-[#a2a2a2] mx-0 min-[768px]:mx-3"
            >
              <span className="min-[768px]:hidden">
                Frequently Asked Questions
              </span>
              <span className="hidden min-[768px]:block">
                Frequently
                <br />
                Asked Questions
              </span>
            </Link>
          </li>
        </ul>

        <ul className="flex justify-center min-[768px]:justify-end max-w-[310px] social-icons m-0 list-unstyled list-inline pt-4 min-[768px]:pt-0 w-auto h-[36px] mb-2 min-[768px]:mb-0">
          <li className="flex justify-center sm:justify-end w-fit">
            <div className="hidden min-[959px]:flex items-center widget social-title mr-3 w-[92px]">
              FOLLOW US{" "}
            </div>

            <div className="min-h-[36px] flex items-center">
              <div className="flex items-center gap-1">
                <a
                  className="trans-ease-all opacity-60 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.facebook.com/AEONCorporation"
                >
                  <img
                    data-pin-nopin="true"
                    alt="Facebook"
                    title="Facebook"
                    src={`${siteUrl}/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_facebook.png`}
                    width="36"
                    height="36"
                  />
                </a>
                <a
                  className="trans-ease-all opacity-60 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/AEONCorp"
                >
                  <img
                    data-pin-nopin="true"
                    alt="Twitter"
                    title="Twitter"
                    src={`${siteUrl}/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_twitter.png`}
                    width="36"
                    height="36"
                  />
                </a>
                <a
                  className="trans-ease-all opacity-60 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/c/AEONCorporation"
                >
                  <img
                    data-pin-nopin="true"
                    alt="YouTube"
                    title="YouTube"
                    src={`${siteUrl}/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_youtube.png`}
                    width="36"
                    height="36"
                  />
                </a>
                <a
                  className="trans-ease-all opacity-60 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.linkedin.com/company/aeon-corporation"
                >
                  <img
                    data-pin-nopin="true"
                    alt="LinkedIn"
                    title="LinkedIn"
                    src={`${siteUrl}/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_linkedin.png`}
                    width="36"
                    height="36"
                  />
                </a>
                <a
                  className="trans-ease-all opacity-60 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/aeon_corporation/"
                >
                  <img
                    data-pin-nopin="true"
                    alt="Instagram"
                    title="Instagram"
                    src={`${siteUrl}/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_instagram.png`}
                    width="36"
                    height="36"
                  />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
}
