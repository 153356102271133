import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import { useLocation, useNavigate } from "react-router-dom";
import { TERipple } from "tw-elements-react";

import { siteUrl } from "lib/constants";
import CorporateOnlineApplicationContent from "../components/CorporateOnlineApplicationContent";
import OnlineApplicationContent from "../components/OnlineApplicationContent";
import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import CorporateSideOnlineApplication from "../components/shared/layouts/CorporateSideOnlineApplication";
import SideOnlineApplication from "../components/shared/layouts/SideOnlineApplication";
import MetaHead from "../components/shared/MetaHead";
import ModalDefault from "../components/shared/ModalDefault";
import usePage from "../contexts/PageContext";
import { ADD_PAGES_PLAIN_CHILDREN } from "../lib/graphql";

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Convert days to milliseconds
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/`;
};

export default function OnlineApplication(props) {
  let navigate = useNavigate();

  const { siteData, setSiteData, acceptCookiesManually } = usePage();
  const cookiesAccepted = getCookieConsentValue("AeonCookieAcceptance");

  const [ privacyPolicyAccepted, setprivacyPolicyAccepted ] = useState(false);
  const [ cookiePolicyAccepted, setcookiePolicyAccepted ] =
    useState(cookiesAccepted);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const interviewType = queryParams.get("type");
  const isCorporate = interviewType === "corporate-division";
  const isWorldWide = interviewType === "worldwide-interviews";

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES_PLAIN_CHILDREN;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation],
  });

  const [ showModal, setShowModal ] = useState(true);

  const options = {
    backdrop: true,
    position: "top",
  };

  function openModal() {
    setShowModal(true);
  }
  const onClose = () => {
    if (!showModal && !privacyPolicyAccepted)
      interviewType !== "corporate-division"
        ? navigate("/recruiting-locations")
        : navigate("/corporate-division");
    setShowModal(false);
  };

  function handlePrivacy() {
    //setprivacyPolicyAccepted(true);
    handleAcceptCookie();
    setShowModal(false);
  }

  const handleAcceptCookie = () => {
    setCookie("AeonCookieAcceptance", true, 365);
    acceptCookiesManually();
  };

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };

      let newPageContent = null;
      let newPageError = null;
      const successObject = {};
      const toApplyObject = {};

      const newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      const curChildren = newPage.children?.edges || null;

      const splitContent = newPage.content.split(/<div class="widget-wrap">/);
      //debugger;
      splitContent.shift();
      toApplyObject.toApplyToAeon = splitContent[0];
      toApplyObject.toApplyToAeonCorporate = splitContent[1];

      newPageContent = isCorporate
        ? toApplyObject.toApplyToAeonCorporate
        : toApplyObject.toApplyToAeon;

      if (curChildren) newSiteData.curChildren = curChildren;
      if (featuredImage?.mediaItemUrl)
        newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      if (curChildren) {
        successObject.success = curChildren.find(
          (child) => child.node.slug === "success"
        )?.node?.content;
        successObject.corporateSuccess = curChildren.find(
          (child) => child.node.slug === "corporate-success"
        )?.node?.content;
        successObject.japanSuccess = curChildren.find(
          (child) => child.node.slug === "japan-success"
        )?.node?.content;
        newPageError = "<span>Error</span>";
      }

      newSiteData.pages = {
        ...siteData.pages,
        [curLocation]: {
          ...newPage,
          newPageContent: newPageContent,
          successObject: successObject,
          toApplyObject: toApplyObject,
          newPageError: newPageError,
          children: curChildren,
          featuredImage: featuredImage,
        },
      };

      newSiteData.prevPage = null;
      newSiteData.nextPage = null;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curChildren: curChildren,
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData?.pages[curLocation];

  const handlePrivacyChange = (e) => {
    setprivacyPolicyAccepted(e.target.checked);
  };

  const handleCookieChange = (e) => {
    setcookiePolicyAccepted(e.target.checked);
  };

  useEffect(() => {
    if (curPage) {
      let newSiteData = { ...siteData };

      const featuredImage = curPage?.featuredImage || null;
      if (featuredImage?.mediaItemUrl)
        newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      setSiteData({
        ...newSiteData,
        curParent: null,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curChildren: curPage?.children || null,
        curSlug: slugId,
        isLoading: false,
      });

      if (siteData && !privacyPolicyAccepted) {
        openModal();
      }
    }
  }, [ curPage ]);

  if (!siteData) return null;
  console.log("curPage", curPage);
  const privacyPopup = siteData.curChildren.find(
    (child) =>
      child.node.slug === "aeon-corporations-data-collection-and-privacy-policy"
  )?.node;

  return (
    <div className="bg-[#fcfcfc] min-h-screen trans-ease-all">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}
          <BackgroundImagePanel url={siteData.curPanelBackground} />
          <main
            id="main-container"
            className="min-h-screen w-full z-10 mt-[224px] relative grid grid-cols-4 min-[576px]:max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4  trans-ease-all"
          >
            <section className="col-span-4 lg:col-span-3 mx-auto w-full -mt-[81px] blue-arrows">
              {curPage && !isCorporate && (
                <OnlineApplicationContent page={curPage} siteData={siteData} />
              )}
              {curPage && isCorporate && (
                <CorporateOnlineApplicationContent
                  page={curPage}
                  siteData={siteData}
                />
              )}
            </section>
            <div className="hidden lg:block h-full w-full max-w-[300px] min-[1600px]:max-w-[350px] ml-[1px]">
              {isCorporate && (
                <CorporateSideOnlineApplication
                  type={interviewType}
                  page={curPage}
                  parent={siteData.curParent}
                  children={siteData.curChildren}
                />
              )}
              {!isCorporate && (
                <SideOnlineApplication
                  type={interviewType}
                  page={curPage}
                  parent={siteData.curParent}
                  children={siteData.curChildren}
                />
              )}
            </div>
          </main>
          <div className="px-8 pb-4 block lg:hidden max-w-[540px] min-[768px]:max-w-[758px] m-auto">
            {isCorporate && (
              <CorporateSideOnlineApplication
                mobileBottom={true}
                type={interviewType}
                page={curPage}
                parent={siteData.curParent}
                children={siteData.curChildren}
              />
            )}
            {!isCorporate && (
              <SideOnlineApplication
                mobileBottom={true}
                type={interviewType}
                page={curPage}
                parent={siteData.curParent}
                children={siteData.curChildren}
              />
            )}
          </div>

          <ModalDefault
            options={options}
            title="AEON Corporation's Data Collection and Privacy Policy"
            location="top-0"
            size="xl"
            showModal={showModal && !siteData.isLoading}
            setShowModal={setShowModal}
            callBack={handlePrivacy}
            onHide={onClose}
            addClasses="!bg-[#ffffff] mt-0 sm:mt-[16px] md:mt-[32px] lg:mt-[64px] lg:text-[18px] mx-auto border border-neutral-100 max-w-[1600px]"
          >
            <>
              {privacyPopup?.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: privacyPopup?.content }}
                />
              )}
              <div className="">
                <input
                  id="acceptancePrivacy"
                  name="acceptancePrivacy"
                  type="checkbox"
                  onChange={handlePrivacyChange}
                />
                <label
                  htmlFor="acceptancePrivacy"
                  className="pl-2 pr-4 cursor-pointer"
                >
                  I understand and agree to AEON Corporation&apos;s Data
                  Collection and Privacy Policy
                </label>
              </div>
              {!cookiesAccepted && (
                <>
                  <div className="mt-4 flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 py-4 dark:border-opacity-50 border-none px-0">
                    <h5 className="text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] leading-normal">
                      AEON Corporation&apos;s Cookie Policy
                    </h5>
                  </div>
                  <div className="mt-4 post-content">
                    <p>
                      This website and its third-party tools use cookies to
                      manage, monitor, and track usage in order to improve our
                      website&apos;s services.
                    </p>
                    <p>
                      For AEON Corporation’s full Cookie Policy,{" "}
                      <a
                        href={`${siteUrl}/application-requirements/cookie-policy/`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        CLICK HERE
                      </a>
                      .
                    </p>

                    <div>
                      <input
                        id="acceptanceCookie"
                        name="acceptanceCookie"
                        type="checkbox"
                        onChange={handleCookieChange}
                      />
                      <label
                        htmlFor="acceptanceCookie"
                        className="pl-2 pr-4 cursor-pointer"
                      >
                        I understand and agree to AEON Corporation&apos;s Cookie
                        Policy
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div
                className={
                  `${
                    privacyPolicyAccepted && cookiePolicyAccepted
                      ? "-auto opacity-100"
                      : "h-0 opacity-0"
                  }` + " mt-2  trans-ease-all trans-ease text-right"
                }
              >
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    onClick={() => handlePrivacy()}
                    className="ml-1 inline-block rounded !px-8 pb-2 pt-2.5 font-medium uppercase leading-normal text-white transition duration-150 ease-in-out btn btn-blue"
                  >
                    Accept
                  </button>
                </TERipple>
              </div>
            </>
          </ModalDefault>
        </>
      )}
    </div>
  );
}
