import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { transformContent, transformImages } from "../../../lib/pageHelper";

import GoogleMap2 from "../GoogleMap2";

export default function PageContent({ page }) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ newPageContent, setNewPageContent ] = useState();

  useEffect(() => {
    if (newPageContent) {
      var imgs = document.querySelectorAll(".post-content img");
      imgs.forEach.call(imgs, function (element, index, array) {
        const floatDir = element.classList.contains("alignleft")
          ? "alignleft"
          : element.classList.contains("alignright")
          ? "alignright"
          : "aligncenter";
        element.setAttribute("loading", "lazy");
        setTimeout(function () {
          const parent = element.closest("span");
          if (parent) {
            const grandParent = parent.closest(".blue-bg-wrap");
            if (grandParent) {
              grandParent.classList.add("animated");
              if (floatDir) grandParent.classList.add(floatDir);

              parent.classList.add("animated");
              setTimeout(function () {
                element.classList.add("animated");
              }, 100);
            }
          }
        }, 150);
      });
    }
  }, [ newPageContent ]);

  useEffect(() => {
    if (page && page?.content) {
      const newPageContent = transformImages(page.content);
      newPageContent.replace("<p></p>", "");
      setNewPageContent(newPageContent);
      setTimeout(function () {
        setIsLoaded(true);
        //console.log("adding animated to sidenav page2");

        const hiddenContent = document.getElementById("page-content");
        if (hiddenContent) hiddenContent.classList.add("animated");
      }, 100);
    }
  }, [ page ]);

  const StaticArrow = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={4}
      stroke="#33a1e7"
      className={`blue w-3 h-3 ml-[1px]`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );

  const HoverArrow = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={4}
      stroke="#33a1e7"
      className={`trans-ease-all opacity-0 group-hover:opacity-100 w-3 h-3 ml-[-6px]`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );

  useEffect(() => {
    const hiddenContent = document.getElementById("page-content");
    if (hiddenContent) {
      setTimeout(() => {
        hiddenContent.classList.add("animated");
      }, 50);
    }
  }, [ page, newPageContent ]);

  return (
    <article className="flex pb-4">
      {page && newPageContent && (
        <div
          id="page-content"
          className="hideme w-full pt-6 min-[768px]:pt-8 px-4 min-[992px]:px-8 min-[1366px]:pt-10 z-50"
        >
          <h1 className="text-center min-[768px]:text-left font-frutiger-light uppercase font-bold text-[#24292e] mb-2 min-[768px]:mb-6 ">
            {page.title}
          </h1>
          {page?.googleMaps?.latitude && isLoaded && (
            <div className="map_block animated">
              <div id="map_canvas" className="animated">
                <GoogleMap2
                  latitude={page?.googleMaps?.latitude}
                  longitude={page?.googleMaps?.longitude}
                  zoom={page?.googleMaps?.zoom}
                  mapId={page.slug}
                />
              </div>
            </div>
          )}
          <div className="post-content mt-3 xl:mt-4 text-justify">
            {transformContent(newPageContent)}
          </div>
        </div>
      )}
      {page && page?.slug === "career-opportunities" && (
        <div
          id="career-opportunities-blue"
          className="mt-1 mt-md-3 mb-4 mt-lg-5 career-opportunities-wrapper"
        >
          <Link
            className="w-fit mt-2 trans-ease-all arrow-link group flex items-center uppercase text-sm font-semibold  hover:text-[#0056b3]  blue border-transparent hover:border-[#33a1e7] border-b-2"
            title="Career Opportunities at AEON"
            to="/career-opportunities"
          >
            <span className="">LEARN MORE</span>
            <StaticArrow />
            <HoverArrow />
          </Link>
        </div>
      )}
    </article>
  );
}
