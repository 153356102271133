import { createContext, useContext, useRef, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

export const themes = {
  light: {
    foreground: "#000000",
    background: "#eeeeee",
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222",
  },
};

const initialSiteData = {
  isLoading: true,
  curSlug: "",
  curLocation: "",
  curLocations: [],
  curChildren: [],
  curParent: [],
  prevLocation: "",
  navigation: {},
  pages: {},
  locations: [],
  prevPage: "",
  nextPage: "",
  panelBackground: "",
  curPanelBackground: "",
};

const initialState = {
  theme: themes.light,
  setTheme: () => {},
  siteData: initialSiteData,
  setSiteData: () => {},
};

const PageContext = createContext(initialState);

export const PageProvider = ({ children }) => {
  const [ theme, setTheme ] = useState(themes.light);
  const [ siteData, setSiteData ] = useState(initialSiteData);
  const cookieRef = useRef(null);
  const autoFocus = (element) => element?.focus();

  const acceptCookiesManually = () => {
    if (cookieRef.current) {
      cookieRef.current.accept(); // Programmatically accept cookies
    }
  };

  return (
    <PageContext.Provider
      value={{ theme, setTheme, siteData, setSiteData, acceptCookiesManually }}
    >
      {children}
      <div className="fixed w-100 bottom-0 left-0 right-0 z-[1000]">
        <CookieConsent
          ref={cookieRef}
          location="bottom"
          buttonText="I ACCEPT"
          cookieName="AeonCookieAcceptance"
          disableStyles={true}
          containerClasses="!bg-[#0189e0] align-self-start !justify-start !items-center flex text-white w-full max-w-[1600px] mx-auto"
          contentClasses="!mx-[14px] !my-[8px] leading-tight text-sm"
          buttonWrapperClasses="mr-4"
          buttonClasses="w-[112px] trans-ease-all btn btn-blue !rounded-md !text-white shadow mt-2 !py-1 !px-4 !m-auto text-sm font-semibold"
          expires={365}
        >
          <div className="post-content text-justify text-base/5">
            Notice: This website and its third-party tools use cookies to
            manage, monitor, and track usage in order to improve our website’s
            services. If you would like to learn more about the cookies we use
            or learn how to withdraw your consent to the use of cookies, please
            refer to our{" "}
            <Link
              ref={autoFocus}
              to="/application-requirements/cookies-policy"
              className="!text-white font-semibold outline-none"
            >
              Cookie Policy
            </Link>
            . You consent to use our cookies if you continue to use this site.
          </div>
        </CookieConsent>
      </div>
    </PageContext.Provider>
  );
};

const usePage = () => {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error("useTheme must be used within a PageContext");
  }
  return context;
};

export default usePage;
