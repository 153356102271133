import { useEffect, useRef, useState } from "react";

export default function Locations({ locations, position }) {
  const [ active, setActive ] = useState(0);

  const [ validLocations, setValidLocations ] = useState([]);
  const [ activeCoords, setActiveCoords ] = useState([ 0, 0 ]);
  const timeoutRef = useRef(null);
  const delay = 5000;

  const parser = new DOMParser();
  const decodeString = (str) =>
    parser.parseFromString(str, "text/html").body.textContent;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setInterval(
      () =>
        setActive((prevIndex) =>
          prevIndex === validLocations.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [ active ]);

  useEffect(() => {
    if (locations && !validLocations.length) {
      const newLocations = [];

      locations.forEach((location) => {
        const curCoords = {
          xcoords: location.node.staffInformation.xcoords,
          ycoords: location.node.staffInformation.ycoords,
        };

        const newCoords = getModifiedCoordinate(curCoords);

        if (location.node.staffInformation?.staffImage && newCoords)
          newLocations.push({
            ...location.node,
            staffInformation: {
              ...location.node.staffInformation,
              xcoords: newCoords.xcoords,
              ycoords: newCoords.ycoords,
            },
          });
      });
      const newActiveCoords = [];

      newLocations.sort(() => Math.random() - 0.5);
      newLocations.forEach((location) => {
        newActiveCoords.push({
          xcoords: location.staffInformation.xcoords,
          ycoords: location.staffInformation.ycoords,
        });
      });

      setActiveCoords([ ...newActiveCoords ]);
      setValidLocations([ ...newLocations ]);
    }
    if (validLocations.length) {
      const setNewCoords = () => {
        if (validLocations.length) {
          const newActiveCoords = [];

          validLocations.forEach((location) => {
            const curCoords = {
              xcoords: location.staffInformation.xcoords,
              ycoords: location.staffInformation.ycoords,
            };
            const newCoords = getModifiedCoordinate(curCoords);
            newActiveCoords.push(newCoords);
          });
          setActiveCoords([ ...newActiveCoords ]);
        }
      };

      window.addEventListener("resize", setNewCoords);

      return () => {
        window.removeEventListener("resize", setNewCoords);
      };
    }
  }, [ locations ]);

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const getModifiedCoordinate = (coords) => {
    const xMod2 = position === "side" ? 50 : 52; // minus from left
    const yMod2 = position === "side" ? 17 : 16; // plus from top

    //const ratio2 = 0.355;
    //const ratio2 = 0.315;
    const ratio2 = 0.31;
    //const ratio2 = 0.3;
    const xcoord = coords.xcoords * ratio2;
    const ycoord = coords.ycoords * ratio2;

    const myXcoord = parseInt(xcoord) - xMod2;
    const myYcoord = parseInt(ycoord) + yMod2;
    return { xcoords: myXcoord, ycoords: myYcoord };
  };

  return (
    <div
      className={`${
        position === "side"
          ? "side-blue overflow-hidden rounded-md custom-shadow mb-3 h-[286px]"
          : "flex-grow"
      }`}
    >
      <div
        className={`${
          position === "side" ? "side-map-wrap" : "home-map-wrap"
        } m-auto trans-ease-slow relative h-full`}
      >
        <div
          id={position === "home" ? "home-messages-wrap" : "side-messages-wrap"}
          className="h-full"
        >
          <div className="side-map-innermap absolute h-full w-full">
            <div className="side-map-map w-[242px]  m-auto relative h-full">
              <div className="trans-ease-slow side-map-marker-big-wrapper absolute w-[242px] top-[100px]">
                {activeCoords[active]?.xcoords &&
                  activeCoords[active]?.ycoords && (
                    <div
                      className={`trans-ease-slow  side-map-marker-big`}
                      style={{
                        marginLeft: `${activeCoords[active].xcoords}px`,
                        marginTop: `${activeCoords[active].ycoords}px`,
                      }}
                    ></div>
                  )}
                {/* {validLocations.map((location, index) => (
                  <div className="pl-4 side-map-marker-big-wrapper absolute">
                    {activeCoords[index]?.xcoords &&
                      activeCoords[index]?.ycoords && (
                        <div
                          className={`side-map-marker-big absolute animated`}
                          style={{
                            marginLeft: `${activeCoords[index].xcoords}px`,
                            marginTop: `${activeCoords[index].ycoords}px`,
                          }}
                        ></div>
                      )}
                  </div>
                ))} */}
              </div>
            </div>
          </div>
          <div id="messages-from-staff" className={`h-full`}>
            {validLocations.map((location, index) => (
              <div
                key={index}
                className="side-container-wrapper w-[242px] m-auto"
              >
                <div
                  key={index}
                  className={`z-1  ${
                    active === index
                      ? "opacity-100 fade-in-text z-10"
                      : "opacity-100 fade-out-text"
                  } absolute left-0 right-0`}
                >
                  {/** leave this here below to use for testing, this turns them all on at once*/}
                  {/* <div className="pl-4 side-map-marker-big-wrapper absolute">{activeCoords[index]?.xcoords && activeCoords[index]?.ycoords && <div className={`side-map-marker-big absolute animated`} style={{ marginLeft: `${activeCoords[index].xcoords}px`, marginTop: `${activeCoords[index].ycoords}px` }}></div>}</div> */}
                  <div className="flex-grow w-full side-messages m-auto relative h-[286px]">
                    <div
                      className="message-block absolute active px-6 pt-3"
                      data-id="524"
                    >
                      <ul
                        className="location-messages"
                        data-src="450,310"
                        href={`/testimonials-from-our-staff/#${location.slug}`}
                      >
                        <li>
                          <div
                            className={`staff-message text-left ${
                              position === "home" ? "text-black" : "text-white"
                            } ${
                              active === index ? "visible" : "invisible"
                            } italic text-[12px] lg:text-[14px] leading-relaxed line-clamp-3`}
                          >
                            <a
                              href={`/testimonials-from-our-staff/#${location.slug}`}
                            >
                              {decodeString(
                                removeTags(
                                  location.staffInformation.staffMessage
                                )
                              )}
                            </a>
                          </div>
                          <div
                            className={`staff-meta h-[148px] ${
                              active === index ? "visible" : "invisible"
                            }`}
                          >
                            <div className="image-wrap relative">
                              <div
                                className={`staff-image absolute drop-shadow-lg drop-shadow-stone-900 shadow-stone-900 ${
                                  position === "home"
                                    ? "mt-[44px]"
                                    : "mt-[26px]"
                                } w-[96px] z-1 h-[86px] rounded-sm`}
                              >
                                <a
                                  href={`/testimonials-from-our-staff/#${location.slug}`}
                                >
                                  {location.staffInformation?.staffImage && (
                                    <img
                                      width="70px"
                                      height="70px"
                                      src={
                                        location.staffInformation.staffImage
                                          .node.mediaItemUrl
                                      }
                                      alt={location.staffInformation.staffTitle}
                                      title={
                                        location.staffInformation.staffTitle
                                      }
                                      className={`map-location-image  border border-b-5 border-r-5 ${
                                        position === "home"
                                          ? "w-[80px] h-[80px] border-[#009de1] mt-[2px] "
                                          : "w-[80px] h-[80px] border-[#FFFFFF] ml-[8px] mt-[7px] "
                                      } rounded-md`}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                            <div className="info-wrap position-relative">
                              <div
                                className={` ${
                                  position === "home"
                                    ? "blue strong"
                                    : "text-white"
                                } staff-title absolute mt-1 text-white font-semibold text-xs left-[80px]`}
                              >
                                <a
                                  href={`/testimonials-from-our-staff/#${location.slug}`}
                                >
                                  <span className="block">
                                    {location.staffInformation.staffTitle}
                                  </span>
                                  {position === "home" && (
                                    <span className="block">
                                      {location.staffInformation.staffLocation}
                                    </span>
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
