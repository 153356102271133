import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import RecruitingLocationsContent from "../components/shared/layouts/RecruitingLocationsContent";
import SideRecruitingLocations from "../components/shared/layouts/SideRecruitingLocations";
import MetaHead from "../components/shared/MetaHead";

import usePage from "../contexts/PageContext";

import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES_CHILDREN } from "../lib/graphql";

export default function RecruitingLocations(props) {
  const { siteData, setSiteData } = usePage();

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1].replace("/", "");

  let curQueries = ADD_PAGES_CHILDREN;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      newSiteData.curPanelBackground = featuredImage?.mediaItemUrl || null;

      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: { ...newPage, children: newPage?.children?.edges || [] },
      };

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        curChildren: newPage?.children?.edges || [],
        curParent: null,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[curLocation];

  useEffect(() => {
    if (curPage) {
      let newSiteData = { ...siteData };

      setSiteData({
        ...newSiteData,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curChildren: curPage?.children?.edges || [],
        curParent: null,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  useEffect(() => {
    const hiddenContent = document.getElementById("page-content");
    if (hiddenContent) {
      setTimeout(() => {
        hiddenContent.classList.add("animated");
      }, 50);
    }
  }, [ curPage, pageData ]);

  if (!siteData) return null;

  return (
    <div id="recruiting-locations-page" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}
          <div className="fixed top-0 w-full h-full bg-[#eef0f3]">
            <div
              id="map-world-wrapper"
              data-speed="100"
              className="fixed top-0 w-full h-full"
            />
          </div>
          <main
            id="main-container"
            className="trans-ease pt-[12px] lg:pt-[36px] min-h-screen min-[576px]:max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto z-10 mb-[60px] grid min-[768px]:grid-cols-9 min-[1200px]:grid-cols-12"
          >
            <section
              id="page-content"
              className="hideme z-50 min-[768px]:col-span-6 min-[1200px]:col-span-9 mx-auto pb-4"
            >
              <RecruitingLocationsContent page={curPage} />
            </section>
            <div className="min-[768px]:col-span-3 w-full h-full px-4 bg-transparent max-w-[540px] min-[768px]:max-w-[758px] m-auto">
              <SideRecruitingLocations
                page={curPage}
                parent={siteData.curParent}
                children={siteData.curChildren}
              />
            </div>
          </main>
        </>
      )}
    </div>
  );
}
