export const siteUrlLive = "https://www.aeonet.com";
export const siteUrlDev2 = "https://dev2.aeonet.com";
export const adminEmailLive = "aeonrecruiting@aeonet.com";
export const devEmailLive = "webtodd@aeonet.com";
export const adminEmailDev2 = "todd@dev2.aeonet.com";

export const siteUrl = siteUrlLive;
//export const siteUrl = siteUrlDev2;

export const wpcf7Url = (formId) => {
  return `${siteUrl}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;
};

export const onlineApplicationID = siteUrl === siteUrlLive ? 29996 : 26355;
export const corporateOnlineApplicationID =
  siteUrl === siteUrlLive ? 29997 : 26391;

export const regionsData = {
  //these are also hardcoded in the graphql file
  2038: "asahikawa-hokkaido",
  1731: "aeon-tokorozawa-saitama",
  1703: "kanazawa-ishikawa",
  1756: "nara",
  1792: "tottori",
  1807: "hakata",
};

export const regionsSlugMap = {
  asahikawa: "asahikawa-hokkaido",
  sapporo: "sapporo-hokkaido",
  amori: "amori",
  sendai: "sendai-miyagi",
  mito: "mito",
  yamagata: "yamagata",
  oyama: "oyama",
  nigata: "niigata",
  kofu: "kofu",
};

export const defaultBannerImages = [
  {
    path: "/english-education",
    url: `${siteUrl}/wp-content/uploads/2012/08/english-education.jpg`,
  },
  {
    path: "Life at AEON",
    url: `${siteUrl}/wp-content/uploads/2012/08/life-at-aeon1.png`,
  },
  {
    path: "Life in Japan",
    url: `${siteUrl}/wp-content/uploads/2019/03/life-in-japan.jpg`,
  },
  {
    path: "/recruiting-locations",
    url: `${siteUrl}/wp-content/themes/wp-bootstrap-child/inc/assets/img/grid-grey.png`,
  },
  {
    path: "/application-requirements",
    url: `${siteUrl}/wp-content/uploads/2012/08/application-requirements.jpg`,
  },
  {
    path: "Frequently Asked Questions",
    url: `${siteUrl}/wp-content/uploads/2012/08/frequently-asked-questions.jpg`,
  },
];

export const menuLinks = [
  {
    name: [ "English", "Education", "English Education" ],
    url: "/english-education",
  },
  { name: [ "Life At", "Aeon", "Life At Aeon" ], url: "/life-at-aeon" },
  { name: [ "Life In", "Japan", "Life In Japan" ], url: "/life-in-japan" },
  {
    name: [ "Recruiting", "Locations", "Recruiting Locations" ],
    url: "/recruiting-locations",
  },
  {
    name: [ "Application", "Requirements", "Application Requirements" ],
    url: "/application-requirements",
  },
];

export const soonestAvailableOptions = [
  { text: "Already in Japan", value: "Already in Japan" },
  { text: "As soon as possible", value: "As soon as possible" },
  { text: "2-4 months", value: "2-4 months" },
  { text: "4-6 months", value: "4-6 months" },
];

export const officeAppliedToOptions = [
  { text: "Texas", value: "Texas" },
  { text: "Tokyo", value: "Tokyo" },
  { text: "Los Angeles", value: "Los Angeles" },
  { text: "New York", value: "New York" },
];

export const visaTypeOptions = [
  { text: "Permanent Resident", value: "Permanent Resident" },
  { text: "Specialist in Humanities", value: "Specialist in Humanities" },
  { text: "Instructor", value: "Instructor" },
  { text: "Working Holiday", value: "Working Holiday" },
  { text: "Student", value: "Student" },
  { text: "Cultural Activities", value: "Cultural Activities" },
  { text: "Designated Activities", value: "Designated Activities" },
  { text: "Spouse", value: "Spouse" },
  { text: "Dependent", value: "Dependent" },
  { text: "Military", value: "Military" },
  { text: "Other", value: "Other" },
];

export const defaultValues = {
  appliedBefore: "No",
  hasDegree: "Yes",
  hasPassport: "No",
  hasVisa: "No",
  isSuccess: false,
  isError: false,
  successContent: null,
};
