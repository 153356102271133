import parse from "html-react-parser";
import { siteUrl } from "lib/constants";
import { Link } from "react-router-dom";

export const SubmitApplication = () => (
  <div className="side-blue text-center mb-4 rounded-md pb-2 custom-shadow">
    <div
      id="interviewing-year-round"
      className="position-relative m-auto tighten trans-ease-fast px-1  min-[992px]:pb-2 min-[992px]:px-2"
    >
      <h5 className="white-title text-center pt-3">
        Interviewing
        <br />
        Year Round Worldwide
      </h5>
      <div className="trans-ease-fast mt-2 min-[992px]:mb-3 w-36 border-b border-white mx-auto" />
      <Link
        title="Submit Application"
        to="/recruiting-locations"
        className="trans-ease-fast group flex items-center justify-center font-bold drop-shadow hover:drop-shadow-lg custom-shadow mx-1 min-[992px]:mx-2 rounded-md mt-3 text-[12px] lg:text-[14px] btn  btn-grey"
      >
        <span>SUBMIT APPLICATION</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={4}
          stroke="#000000"
          className="blue w-3 h-3 min-[992px]:mb-[.1rem]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={4}
          stroke="#000000"
          className="trans-ease-all opacity-0 w-0 group-hover:w-3 group-hover:opacity-100 ml-[-6px]  h-3 min-[992px]:mb-[.1rem]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </Link>
    </div>
  </div>
);

export const GetTheFaqs = () => (
  <div className="side-grey text-center mb-4 rounded-md pb-2 custom-shadow trans-ease-all">
    <div
      id="frequently-asked-questions"
      className="position-relative m-auto tighten trans-ease-fast px-1  min-[992px]:pb-2 min-[992px]:px-2"
    >
      <h5 className="black-title black-line text-center pt-3">
        Frequently
        <br />
        Asked Questions
      </h5>
      <div className="trans-ease-fast mt-2 min-[992px]:mb-3 w-36 border-b border-[#333] mx-auto" />
      <Link
        title="Get the Frequently Asked Questions"
        to="/frequently-asked-questions"
        className="trans-ease-fast group flex items-center justify-center font-bold drop-shadow hover:drop-shadow-lg custom-shadow mx-1 min-[992px]:mx-2 rounded-md mt-3 text-[12px] lg:text-[14px]  btn  btn-blue"
      >
        <span>GET THE FAQ'S</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={4}
          stroke="#ffffff"
          className="blue w-3 h-3 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={4}
          stroke="#ffffff"
          className="trans-ease-all opacity-0 w-0 group-hover:w-3 group-hover:opacity-100 ml-[-6px]  h-3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </Link>
    </div>
  </div>
);

export const StaticArrow = ({ direction }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={4}
    stroke="#33a1e7"
    className={`max-[768px]:-mt-[2px] blue w-3 h-3 ${
      direction === "left" ? "rotate-180 mr-[1.2px]" : "ml-[1px]"
    }`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m8.25 4.5 7.5 7.5-7.5 7.5"
    />
  </svg>
);

export const HoverArrow = ({ direction }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={4}
    stroke="#33a1e7"
    className={`max-[768px]:-mt-[2px] trans-ease-all opacity-0 group-hover:opacity-100 w-3 h-3 ${
      direction === "left" ? "rotate-180 mr-[-6px] " : "ml-[-6px]"
    }`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m8.25 4.5 7.5 7.5-7.5 7.5"
    />
  </svg>
);

export const stripHTML = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const transformContent = (htmlString) => {
  return parse(htmlString, {
    replace: (domNode) => {
      if (domNode.name === "a") {
        const href = domNode.attribs?.href || "";
        const title = domNode.attribs?.title || "";
        const target = domNode.attribs?.target || "";
        const isInternal = href.startsWith(siteUrl) || href.startsWith("/");
        const linkText = domNode.children?.[0]?.data || "";

        if (target === "_blank") {
          return null;
        }

        if (isInternal) {
          const internalPath = href.replace(siteUrl, "");
          return (
            <Link to={internalPath} title={title}>
              {linkText}
            </Link>
          );
        }
      }
    },
  });
};

export const transformImages = (str) => {
  const images = str.match(/<img[^>]*>/g);
  if (images) {
    const newImages = [];

    images.forEach((imageHtml, index) => {
      if (!imageHtml.includes("no-blue")) {
        const isLeft = imageHtml.includes("alignleft");
        const isRight = imageHtml.includes("alignright");
        // const isCenter = imageHtml.includes("aligncenter");

        const curSize = isLeft
          ? "alignleft"
          : isRight
          ? "alignright"
          : "aligncenter";

        const newImage = imageHtml.replace(
          /<img[^>]*>/g,
          `<span class='trans-ease-fast blue-bg-wrap ${curSize}'><span class='trans-ease-fast blue-bg'>$&</span></span>`
        );
        newImages.push({
          old: imageHtml,
          new: newImage,
        });
      }
    });
    newImages.forEach((newImageSet) => {
      str = str.replace(newImageSet.old, newImageSet.new);
    });
  }
  return str;
};

const preloadedImages = {};

export function preloadImage(url) {
  if (!preloadedImages[url]) {
    const img = new Image();
    img.src = url;
    preloadedImages[url] = img;
  }
}

export { preloadedImages };
