import { regionsSlugMap } from "lib/constants";
import { Link, useLocation } from "react-router-dom";
import usePage from "../../../contexts/PageContext";
import DefaultSideContent from "./DefaultSideContent";

export default function PageNavigation({
  size,
  page,
  parent,
  children,
  parentSlugOverride,
}) {
  const { siteData } = usePage();
  const location = useLocation();
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const isLoading =
    curLocations[0] !== siteData.curLocations[0] || siteData.isLoading;
  const locationSlug = location.pathname.includes("location/")
    ? "location"
    : null;
  const parentSlug =
    parentSlugOverride ||
    locationSlug ||
    page?.parent?.node?.slug ||
    parent?.slug ||
    parent?.node?.slug ||
    page?.slug ||
    null;

  // useEffect(() => {
  //   const navigation = document.getElementById("page-navigation");
  //   const sideContent = document.getElementById("side-content");

  //   if (navigation && sideContent) {
  //     const listItems = navigation.querySelectorAll("li");
  //     const animationDelay = 15;
  //     const totalDuration = listItems.length * animationDelay;

  //     listItems.forEach((item, index) => {
  //       const delay = index * animationDelay;
  //       setTimeout(() => {
  //         item.classList.add("h-auto", "animated");
  //       }, delay);
  //     });

  //     setTimeout(() => {
  //       sideContent.classList.add("animated");
  //     }, totalDuration);
  //   }
  // }, [ children ]);

  // const handleClick = (event) => {
  //   const linkHref = event.currentTarget.getAttribute("href");
  //   const currentPath = location.pathname;

  //   if (linkHref === currentPath) {
  //     return;
  //   }

  //   const hiddenContent = document.getElementById("page-content");
  //   if (hiddenContent) hiddenContent.classList.remove("animated");
  // };

  return (
    <aside
      id="secondary"
      className="sidebar sticky top-[53px] h-100 pt-[1.2rem] mb-4 px-1 bg-[#fcfcfc]"
    >
      <section className="rounded-sm sticky top-[53px] px-[1rem] min-[768px]:px-[1.35rem] min-[992px]:px-[1.5rem] min-[1366px]:px-[2rem] pt-2 min-[768px]:pt-4 min-[992px]:pt-4 min-[1366px]:pt-6 min-[1844px]:pt-5 z-50 min-h-[100px]">
        {/* isLoading ? "opacity-0" : "" */}
        <ul
          id="page-navigation"
          className={`${
            isLoading ? "" : ""
          } transition-all transe-ease-all transe-ease-slow uppercase font-frutiger-ce4 border-b-4 border-[#33b1e7] mb-4`}
        >
          {/* onClick={(e) => handleClick(e)} */}
          <li className="min-[768px]:text-[15px] min-[992px]:text-[18px] min-[1592px]:text-[21px] min-[1844px]:text-[23px] ">
            {parentSlug !== "location" && (
              <Link
                to={`/${parentSlug || ""}`}
                className={`flex text-[#008ae1] pb-.5 lg:pb-1 min-[1592px]:pb-1.5 w-fit`}
              >
                <span>{parent?.title || page?.title}</span>
              </Link>
            )}
            {parentSlug === "location" && (
              <Link
                to={`/${parentSlug || ""}/${
                  children[0]?.slug || children[0]?.node?.slug || "#"
                }`}
                className={`flex text-[#008ae1] py-.5 lg:py-1 min-[1592px]:py-1.5 w-fit`}
              >
                <span>{parent?.title || page?.title}</span>
              </Link>
            )}
          </li>
          {/* h-0 hideme */}
          {children &&
            children.length > 0 &&
            children.map((child, idx) => {
              const childTitle =
                child.title?.toLowerCase() || child.node?.title?.toLowerCase();
              const slugFromMap = regionsSlugMap[childTitle];
              const childSlug = child?.slug || child?.node?.slug;
              const isActive =
                page?.slug === (slugFromMap || childSlug) ||
                (page?.parent &&
                  page?.slug === (child?.slug || child?.node?.slug));
              return (
                <li key={idx} className="divider-dots-sidebar">
                  <Link
                    to={`/${parentSlug ? parentSlug + "/" : ""}${
                      child?.slug || child?.node?.slug || ""
                    }`}
                    className={`flex hover:pl-[1px] ${
                      isActive ? "text-[#008ae1]" : "hover:text-[#008ae1]"
                    } py-1 min-[1592px]:py-1.5 w-fit min-[768px]:text-[13px] min-[992px]:text-[15px] min-[1592px]:text-[17px]`}
                  >
                    <span className="mt-[.15rem]">{childTitle}</span>
                  </Link>
                </li>
              );
            })}
        </ul>
        {/* hideme   className={`${isLoading ? "opacity-0 " : ""}`}*/}
        {size === "full" && (
          <DefaultSideContent
            locations={siteData.locations}
            loading={isLoading}
          />
        )}
      </section>
    </aside>
  );
}
