import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_INTERVIEWS } from "../lib/graphql";

export default function InterviewLocations() {
  const [ active, setActive ] = useState(0);
  const [ animated, setAnimated ] = useState(0);
  const { siteData, setSiteData } = usePage();

  const [ interviewsContent, setContent ] = useState([]);

  const GET_PAGE_DATA2 = gql`
  query getPageData2($id: String!) {    
    ${ADD_INTERVIEWS}
  }
  `;

  const { data: interviewsData } = useQuery(GET_PAGE_DATA2, {
    variables: {
      id: "worldwide-interviews",
      skip: siteData?.pages["worldwide-interviews"],
    },
  });

  useEffect(() => {
    if (interviewsData && siteData?.pages) {
      let newSiteData = { ...siteData };
      const interviews = interviewsData?.pages?.nodes[0]?.content || null;
      newSiteData.pages = {
        ...siteData.pages,
        "home-page": { ...siteData["home-page"], interviews2: interviews },
      };
      const newInterviewsContent = interviews.split("<ul>");
      newInterviewsContent.shift();
      setContent(newInterviewsContent);
    }
  }, [ interviewsData ]);

  const timeoutRef = useRef(null);
  const delay = 3000;
  const delayAnimated = 50;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = setTimeout(
        () =>
          setAnimated((prevIndex) =>
            prevIndex === interviewsContent.length - 1 ? 0 : prevIndex + 1
          ),
        delayAnimated
      );
      setActive((prevIndex) =>
        prevIndex === interviewsContent.length - 1 ? 0 : prevIndex + 1
      );
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [ active ]);

  return (
    <div className="w-100 flex w-full justify-center items-center">
      <div className="flex items-center">
        <div className="w-[200px] relative upcoming-interviews trans-ease-slow hover:text-white  hover:bg-[#008ae1b3] border border-[#008ae1b3] mx-10 mb-4 px-10 rounded-md pt-2 pb-1 text-sm font-thin">
          <Link to="/recruiting-locations">
            <div
              id="interviewing-locations"
              className="upcoming-interviews text-center leading-tight h-10 overflow-hidden"
            >
              {interviewsContent.map((interview, index) => (
                <ul
                  key={index}
                  className={`${
                    active === index
                      ? "opacity-100 fade-in-text"
                      : "invisible opacity-0 fade-out-text"
                  } ${animated !== index ? "" : ""} absolute left-0 right-0`}
                  dangerouslySetInnerHTML={{ __html: interview }}
                />
              ))}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
