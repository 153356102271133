import { useState } from "react";

const DropdownListSmall = ({ items }) => {
  const [ openItems, setOpenItems ] = useState([]);

  const toggleItem = (idx) => {
    setOpenItems((prev) =>
      prev.includes(idx)
        ? prev.filter((item) => item !== idx)
        : [ ...prev, idx ]
    );
  };

  return (
    <ul className="blue-triangles w-full">
      {items &&
        items.map((item, idx) => (
          <li aria-expanded={openItems.includes(idx)} key={`item-${idx}`}>
            <button
              onClick={() => toggleItem(idx)}
              className="text-left"
              aria-expanded={openItems.includes(idx)}
            >
              <h6
                className="inline blue-title blue uppercase font-bold text-sm"
                title={item.title}
              >
                {item.title}
              </h6>
            </button>
            <div
              className={`overflow-hidden transition-all ${
                openItems.includes(idx)
                  ? "max-h-screen trans-ease-slow"
                  : "max-h-0"
              }`}
            >
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          </li>
        ))}
    </ul>
  );
};

export default DropdownListSmall;
