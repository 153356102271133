import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import HomePageContent from "../components/HomePageContent";
import CarouselContent from "../components/shared/CarouselContent";
import MetaHead from "../components/shared/MetaHead";

import { gql, useQuery } from "@apollo/client";
import { siteUrl } from "lib/constants";
import usePage from "../contexts/PageContext";
import {
  ADD_LOCATIONS_WITH_CONTENT,
  ADD_PAGES,
  ADD_SLIDES,
} from "../lib/graphql";

export default function HomePage(props) {
  const { siteData, setSiteData } = usePage();
  const [ pageIsReady, setPageIsReady ] = useState(true);
  const location = useLocation();
  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;

  let curQueries = ADD_PAGES + ADD_SLIDES + ADD_LOCATIONS_WITH_CONTENT;

  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: "home-page" },
    skip: siteData?.pages[curLocation] && siteData?.locations,
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      const newPage = pageData.pages?.nodes[0] || [];
      const locations = pageData?.locations?.edges || [];
      const slides = pageData?.slides?.edges || [];

      newSiteData.locations = locations;
      newSiteData.pages = {
        ...siteData.pages,
        "home-page": { ...newPage, slides: slides },
      };

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curChildren: [],
        curParent: null,
        curSlug: "home-page",
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages["home-page"];

  useEffect(() => {
    if (curPage) {
      setPageIsReady(true);
    }
  }, [ curPage ]);

  if (!siteData) return null;
  if (curPage && !curPage?.seo) {
    console.log("missing SEO!!! curPage", curPage);
  }

  return (
    <div id="home-page" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}
          <div
            id="home-slideshow"
            className="relative container-fluid mx-auto transition-all trans-ease-slow"
          >
            <>
              <div className="relative top-0 opacity-0 -mt-[6rem]">
                <img
                  src={`${siteUrl}/wp-content/uploads/2019/02/AEON-Teach-English-in-Japan-Innovator-in-English-Language-Education.jpg`}
                  className="block w-full"
                  alt="AEON"
                />
              </div>
              {curPage?.slides && <CarouselContent slides={curPage.slides} />}
            </>
          </div>

          <main
            id="main-container"
            className="trans-ease min-h-screen -mt-[56px] min-[1024px]:-mt-[36px] w-full z-10 relative max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all"
          >
            {curPage && pageIsReady && <HomePageContent page={curPage} />}
          </main>
        </>
      )}
    </div>
  );
}
