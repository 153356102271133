import { ApolloProvider } from "@apollo/client/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import client from "./lib/apollo";

import CareerOpportunities from "pages/CareerOpportunities";
import CorporateDivision from "pages/CorporateDivision";
import DefaultPage from "pages/DefaultPage";
import FAQS from "pages/FAQS";
import HomeLoader from "pages/HomeLoader";
import LocationPage from "pages/LocationPage";
import SideNavPage from "pages/SideNavPage";
import TestimonialsFromOurStaff from "pages/TestimonialsFromOurStaff";
import MainWrapper from "./components/shared/layouts/MainWrapper";
import { PageProvider } from "./contexts/PageContext";
import OnlineApplication from "./pages/OnlineApplication";
import RecruitingLocations from "./pages/RecruitingLocations";

//import { preloadImage, defaultBannerImages } from "./lib/pageHelper";

function App(props) {
  //let { slug, catslug } = useParams();

  // useEffect(() => {
  //   // preloadImages(defaultBannerImages);
  //   //defaultBannerImages.forEach(preloadImage);
  //   defaultBannerImages.forEach((image) => {
  //     preloadImage(image.url);
  //   });
  // }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith("/") && location.pathname !== "/") {
      navigate(location.pathname.slice(0, -1), { replace: true });
    }
  }, [ location, navigate ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={client}>
        <PageProvider>
          <MainWrapper>
            <Routes>
              <Route path="/" element={<HomeLoader {...props} />} />
              <Route
                path="/search/:search"
                element={<HomeLoader {...props} />}
              />
              <Route
                path="/english-education"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/english-education/:slug"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/life-at-aeon"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/life-at-aeon/:slug"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/life-in-japan"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/life-in-japan/:slug"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/location"
                element={<LocationPage sidebar="small" {...props} />}
              />
              <Route
                path="/location/:slug"
                element={<LocationPage sidebar="small" {...props} />}
              />
              <Route
                path="/application-requirements"
                element={<SideNavPage sidebar="small" {...props} />}
              />
              <Route
                path="/application-requirements/:slug"
                element={<SideNavPage sidebar="small" {...props} />}
              />
              <Route
                path="/testimonials-from-our-staff"
                element={<TestimonialsFromOurStaff {...props} />}
              />
              <Route
                path="/career-opportunities"
                element={<CareerOpportunities {...props} />}
              />
              <Route
                path="/frequently-asked-questions"
                element={<FAQS {...props} />}
              />
              <Route
                path="/recruitment-information-for-japanese-citizens"
                element={<DefaultPage {...props} />}
              />
              <Route path="/about-aeon" element={<SideNavPage {...props} />} />
              <Route
                path="/about-aeon/:slug"
                element={<SideNavPage {...props} />}
              />
              <Route
                path="/corporate-division"
                element={<CorporateDivision {...props} />}
              />
              <Route
                path="/recruiting-locations"
                element={<RecruitingLocations {...props} />}
              />
              <Route
                path="/online-application-form"
                element={<OnlineApplication {...props} />}
              />
            </Routes>
          </MainWrapper>
        </PageProvider>
      </ApolloProvider>
    </LocalizationProvider>
  );
}

export default App;
