import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import PageContent from "../components/shared/layouts/PageContent";
import MetaHead from "../components/shared/MetaHead";

import { gql, useQuery } from "@apollo/client";
import usePage from "../contexts/PageContext";
import { ADD_PAGES } from "../lib/graphql";

export default function DefaultPage(props) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: {
      id: slugId,
    },
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let curPage = pageData.pages?.nodes[0] || [];

      if (!siteData.pages[slugId]) {
        newSiteData.pages = {
          ...siteData.pages,
          [slugId]: {
            ...curPage,
            featuredImage: curPage?.featuredImage?.node || null,
          },
        };
      }
      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  if (!siteData) return null;

  const curPage = siteData.pages[siteData.curSlug];
  const featuredImage = curPage?.featuredImage?.mediaItemUrl || null;

  return (
    <div id="default-page" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}
          <BackgroundImagePanel url={featuredImage} />
          <main
            id="main-container"
            className="trans-ease min-h-screen w-full z-10 mt-[197px] md:mt-[208px] lg:mt-[224px] relative max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all"
          >
            <section className="bg-[#fcfcfc] w-full col-span-3 -mt-[81px] blue-arrows">
              <PageContent page={siteData.pages[siteData.curSlug]} />
            </section>
          </main>
        </>
      )}
    </div>
  );
}
