import { useState, useEffect } from "react";

function useScreenSize() {
  const [ isScreenSmall, setIsScreenSmall ] = useState(window.innerWidth < 1024);

  useEffect(() => {
    let resizeTimeout;

    const handleResize = () => {
      clearTimeout(resizeTimeout);

      resizeTimeout = setTimeout(() => {
        const screenSmall = window.innerWidth < 1024;
        setIsScreenSmall(screenSmall);
      }, 200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(resizeTimeout);
    };
  }, []);

  return isScreenSmall;
}

export default useScreenSize;
