import dayjs from "dayjs";

export const formatDatePickerValue = (value, views) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (views.length === 1) {
    // Year view
    return dayjs(value).year();
  }

  if (views.length === 2) {
    // Month and Year view
    return `${months[dayjs(value).month()]} ${dayjs(value).year()}`;
  }

  // Default fallback (return full date)
  return value;
};

export const formatDatePickerValue2 = (value, views) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const yearFunc = (value) => dayjs(value).year();
  const monthYearFunc = (value) =>
    `${months[dayjs(value).month()]} ${dayjs(value).year()}`;

  if (views.length === 1) return yearFunc(value);
  if (views.length === 2) return monthYearFunc(value);
  return value;
};
