import React from "react";
import { TECarousel, TECarouselItem } from "tw-elements-react";

export default function CarouselContent({ slides }) {
  const sharedClasses =
    "absolute bottom-0 top-0 z-30 flex w-[5%] items-center justify-center border-0 bg-black text-opacity-80 hover:text-opacity-100 bg-opacity-40 hover:bg-opacity-30 p-0 text-center hover:text-white  trans-ease-all  ";

  const btnClasses = {
    nextBtn: `nextBtn right-0 ${sharedClasses}`,
    prevBtn: `prevBtn left-0 ${sharedClasses}`,
  };

  return (
    <div className="absolute top-0">
      <TECarousel
        theme={btnClasses}
        showControls
        pause={false}
        interval={4000}
        ride="carousel"
        className="carousel-wrapper -mt-[6rem]"
        nextBtnIcon={<span className="carousel-control-next-icon"></span>}
        prevBtnIcon={<span className="carousel-control-prev-icon"></span>}
      >
        <div className="carousel-items relative w-full overflow-hidden after:clear-both after:block after:content-['']">
          {slides.map((slide, index) => (
            <TECarouselItem
              key={index}
              itemID={index + 1}
              className="carousel-item relative float-left -mr-[100%] hidden w-full trans-ease-slow motion-reduce:transition-none h-full"
            >
              {/* <img src={slide?.node?.featuredImage?.node?.guid} height={slide?.node?.featuredImage?.node?.mediaDetails.sizes[3]?.height} width={slide?.node?.featuredImage?.node?.mediaDetails.sizes[3]?.width} className="block w-full h-full" alt="..." /> */}

              <img
                src={slide?.node?.featuredImage?.node?.guid}
                className="block w-full h-full"
                alt="..."
              />
              <div className="caption-wrapper rounded-sm animate-in fade-in zoom-in custom-shadow absolute inset-x-[15%] bottom-5 hidden px-5 trans-ease-slow py-2 bg-[#008ae1cc] duration-1000 overflow-hidden hidden-caption md:block w-fit">
                <div className="inner-caption flex items-center h-full py-1 lg:py-2 pt-1 lg:pt-2">
                  <div
                    className="animate-out slide-out-from-left animate-in slide-in-from-bottom  text-l lg:text-xl text-white font-frutiger-roman trans-ease-slow hidden-caption h-auto"
                    dangerouslySetInnerHTML={{ __html: slide?.node?.content }}
                  />
                </div>
              </div>
            </TECarouselItem>
          ))}
        </div>
      </TECarousel>
    </div>
  );
}
