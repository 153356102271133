import { useEffect, useState } from "react";

export const useHandleScroll = () => {
  const [ curScrollDirection, setScrollDirection ] = useState(0);
  const [ curScrollPosition, setScrollPosition ] = useState(0);

  let throttleTimer = false;

  const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;
    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  useEffect(() => {
    let prevScroll = 0;

    const getScrollPosition = () => {
      const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
      const scrollTop = isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop;

      const getPosition = window.scrollY || scrollTop;
      setScrollPosition(getPosition);
      return getPosition;
    };

    const isScrollingDown = () => {
      const scrolledPosition = getScrollPosition();
      const isScrollDown = scrolledPosition > prevScroll;

      prevScroll = scrolledPosition;
      return isScrollDown;
    };

    const checkScroll = () => {
      setScrollDirection(isScrollingDown());
    };

    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    const checkWindowMotion = () => {
      if (mediaQuery && !mediaQuery.matches) {
        getScrollPosition();
        throttle(checkScroll, 300);
      }
    };

    window.addEventListener("scroll", checkWindowMotion);

    return () => window.removeEventListener("scroll", checkWindowMotion);
  }, []);

  return [ curScrollDirection, curScrollPosition ];
};
