import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import DropdownContent from "../components/shared/layouts/DropdownContent";
import MetaHead from "../components/shared/MetaHead";

import { gql, useQuery } from "@apollo/client";
import { stripHTML } from "lib/pageHelper";
import usePage from "../contexts/PageContext";
import { ADD_PAGES } from "../lib/graphql";

export default function FAQS(props) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/")
    ? location.pathname.split("/").filter((item) => item !== "")
    : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;

      const newPageContent = newPage.content
      .replaceAll("<h2>", "|<h2>")
      .split("|");
      newPageContent.shift();
      const newContent = [];

      newPageContent.forEach((item, idx) => {
        const newItems = item.replace("</h2>", "</h2>|").split("|");
        newContent.push({
          question: stripHTML(newItems[0]),
          answer: newItems[1],
        });
      });
      newSiteData.curPanelBackground = featuredImage?.mediaItemUrl || null;

      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: {
          ...newPage,
          featuredImage: featuredImage,
          pageContent: newContent,
        },
      };

      newSiteData.prevPage = null;
      newSiteData.nextPage = null;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[slugId];
  const featuredImage = curPage?.featuredImage?.mediaItemUrl || null;

  useEffect(() => {
    if (curPage) {
      let newSiteData = { ...siteData };

      const featuredImage = curPage?.featuredImage?.node || null;
      if (featuredImage?.mediaItemUrl)
        newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      setSiteData({
        ...newSiteData,
        curParent: null,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  useEffect(() => {
    const hiddenContent = document.getElementById("page-content");
    if (hiddenContent) {
      setTimeout(() => {
        hiddenContent.classList.add("animated");
      }, 50);
    }
  }, [ curPage, pageData ]);

  if (!siteData) return null;

  return (
    <div id="faq-page" className="min-h-screen">
      {curPage && (
        <>
          {curPage?.seo && <MetaHead seoObject={curPage} />}
          <BackgroundImagePanel url={featuredImage} />
          <main
            id="main-container"
            className=" transition-all trans-ease-slow min-h-screen w-full z-10 mt-[197px] md:mt-[208px] lg:mt-[224px] relative max-w-[540px] min-[768px]:max-w-[758px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 trans-ease-all"
          >
            <section className="bg-[#fcfcfc] col-span-9 mx-auto w-full -mt-[81px]">
              <DropdownContent htmlAnswer={true} page={curPage} />
            </section>
          </main>
        </>
      )}
    </div>
  );
}
